import React, { useState } from "react";

import {
  Add,
  Category,
  CloseIcon,
  CourseBuyLink,
  CourseLink,
  CourseName,
  Descrip,
  EstimatedTime,
} from "../../assets";
import axios from "axios";

const EditModal = ({ setShowModal, type, data, id }) => {
  const [courseName, setCourseName] = useState(data.title);
  const [courseLink, setCourseLink] = useState(data.link);
  const [courseBuyLink, setCourseBuyLink] = useState(data.buyLink);
  const [category, setCategory] = useState(data.category);
  const [estimatedTime, setEstimatedTime] = useState(data.time);
  const [descrip, setDescrip] = useState(data.descrip);
  const [img, setImg] = useState(data.image);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let newImg = img;

      if (img !== data.image) {
        const formData = new FormData();
        formData.append("file", img);
        formData.append("upload_preset", "yi1vonhk");

        const upload = await axios.post(
          "https://api.cloudinary.com/v1_1/dsfnoazam/image/upload",
          formData
        );
        newImg = upload.data.secure_url;
      }

      if (type === "Curriculum") {
        const res = await axios.patch(
          `${process.env.REACT_APP_API}/curriculum/${data.id}/`,
          {
            career_title: courseName,
            curriculum_image: newImg,
            description: descrip,
            affiliate_link: courseBuyLink,
            total_completion_time: estimatedTime,
            learn_more_link: courseLink,
            reference: courseLink,
          }
        );
        console.log(res);
      } else {
        const res = await axios.patch(
          `${process.env.REACT_APP_API}/courses/${data.id}/`,
          {
            course_title: courseName,
            description: descrip,
            course_image: newImg,
            affiliate_link: courseBuyLink,
            estimated_time: estimatedTime,
            link_to_course: courseLink,
            label: category,
          }
        );
        console.log(res);
      }
      setShowModal(false);
    } catch (err) {
      setShowModal(false);
      console.log("Add");
    }
  };

  return (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          setShowModal(false);
        }
      }}
      className="deleteModal__container"
    >
      <div className="addNewCourse">
        <CloseIcon className="close" onClick={() => setShowModal(false)} />
        <div className="addNewCourse__title">Edit {type}</div>
        <div className="addNewCourse__container">
          <section>
            <menu>
              {img && <img src={img} alt="" />}
              <label htmlFor="avatar">
                <Add />
              </label>
              <input
                type="file"
                id="avatar"
                name="avatar"
                required
                accept="image/*"
                onChange={(e) => {
                  // setImg(e.target.files[0]);
                  let reader = new FileReader();
                  reader.readAsDataURL(e.target.files[0]);
                  reader.onload = () => {
                    console.log(reader);
                    setImg(reader.result);
                  };
                }}
              />
            </menu>
            <form onSubmit={handleSubmit}>
              <label>
                <CourseName />
                <input
                  placeholder="Curriculum Name"
                  type="text"
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />
              </label>
              <label>
                <CourseLink />
                <input
                  placeholder="Curriculum Link"
                  type="text"
                  value={courseLink}
                  onChange={(e) => setCourseLink(e.target.value)}
                />
              </label>
              <label>
                <CourseBuyLink />
                <input
                  placeholder="Curriculum Buy Link"
                  type="text"
                  value={courseBuyLink}
                  onChange={(e) => setCourseBuyLink(e.target.value)}
                />
              </label>
              <label>
                <Category />
                <input
                  placeholder="Category"
                  type="text"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </label>
              <label>
                <EstimatedTime />
                <input
                  placeholder="Estimated Time"
                  type="text"
                  value={estimatedTime}
                  onChange={(e) => setEstimatedTime(e.target.value)}
                />
              </label>
              <div>
                <Descrip />
                <textarea
                  placeholder="Add Description"
                  draggable={false}
                  value={descrip}
                  onChange={(e) => setDescrip(e.target.value)}
                ></textarea>
              </div>
              <button type="submit">Save</button>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
