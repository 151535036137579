import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Rocket,
  MainImg,
  Coursera,
  SkillShare,
  TeachForAmerica,
  LinkedIn,
  Spark,
  NoEmoji,
  PurpleBall,
  PinkBall,
  PlumBall,
  PHLogo,
  Arrow,
  Search,
  Hands,
  Hand,
  Companies,
  PlusSign,
  Requirements,
  Infinity,
  EqualSign,
} from "../../assets/index.js";

import "./Jumbotron.scss";

const Jumbotron = () => {
  const navigate = useNavigate();

  return (
    <div className="jumbotron">
      <div className="jumbotron__title">
        <img src={Spark} alt="" />
        Tech Career Training <img src={Spark} alt="" />
      </div>
      <div className="jumbotron__slogan">
        Your favorite brand just became a university.
      </div>
      <div className="jumbotron__button-group">
        <button onClick={()=> navigate('/career-finder')}>
          Find Your Match
          <img src={Hands} alt="" />
        </button>
        <button onClick={()=> navigate("/tech-careers")}>
          Browse Careers <Search />
        </button>
      </div>
      <div className="jumbotron__subtitle">
        A curriculum designed around your dream job.
      </div>
      <p className="jumbotron__descrip">
        We extract real job description skills and match them to the industry’s
        top courses.
      </p>
      <div className="jumbotron__benefits">
        <img src={Companies} alt="companies" />
        <PlusSign />
        <img src={Requirements} alt="requirements" />
        <EqualSign />
        <div className="break"></div>
        <img src={Infinity} alt="Infinity" />
      </div>
    </div>
  );
};

export default Jumbotron;
