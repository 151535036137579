import React, { useEffect, useState } from "react";
import TypewriterComponent from "typewriter-effect";
import Carousel from "react-multi-carousel";
import Storage from "../../services/storageService";
import ReactGA from "react-ga";

import {
  CareerButton,
  SideBarFilterMenu,
  TechCareersFilterCard,
  StartJourney,
} from "../../components";
import {
  NbaAllStar,
  NBA,
  Channel,
  Triller,
  Epic,
  StyleSeat,
  SparkSign,
  CheckboxIcon,
  SprinkleStar,
  CloseFilter,
} from "../../assets";

import "./TechCareers.scss";
import axios from "axios";
import getWishlist from "../../services/getWishlist";
import { useNavigate } from "react-router-dom";

const TechCareers = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1230, min: 730 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 730, min: 600 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    Smmobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const [filters, setFilters] = useState({
    brands: [],
    skills: [],
    industries: [],
    locations: [],
    roles: [],
  });

  const [curriculum, setCurriculum] = useState("");
  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_API}/curriculum`);
      setCurriculum(res.data);
      console.log(res.data);
      const w = await getWishlist();
      setWishlist(w?.curriculums);

      console.log(w);
    };
    getData();
  }, []);

  const resetFilters = async () => {
    setFilters({
      brands: [],
      skills: [],
      industries: [],
      locations: [],
      roles: [],
    });
    const res = await axios.get(`${process.env.REACT_APP_API}/curriculum`);
    setCurriculum(res.data);
    if (9 >= res.data.length) {
      setLoad(res.data.length);
    } else {
      setLoad(9);
    }
  };

  const handleFilters = async () => {
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_API
        }/curriculum?brands__industry__industry_name__in=${
          filters.industries.length > 0 ? filters.industries.join(",") : ""
        }&brands__brand_name__in=${
          filters.brands.length > 0 ? filters.brands.join(",") : ""
        }&locations__location__in=${
          filters.locations.length > 0 ? filters.locations.join(",") : ""
        }&role__name__in=${
          filters.roles.length > 0 ? filters.roles.join(",") : ""
        }&specific_skills__skill__in=${
          filters.skills.length > 0 ? filters.skills.join(",") : ""
        }`
      );
      setCurriculum(res.data);
      if (res.data.length < 9) {
        setLoad(res.data.length);
      } else {
        setLoad(9);
      }

      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const [load, setLoad] = useState(9);

  const handleLoad = () => {
    if (load + 9 >= curriculum.length) {
      setLoad(curriculum.length);
    } else {
      setLoad(load + 9);
    }
  };

  const handleClick = (value, type) => {
    const data = filters[type].filter((e) => e !== value);
    setFilters({ ...filters, [type]: data });
  };
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <div className="tech-careers-container">
        <span className="tech-careers-container__title">
          Beyond the&nbsp;
          <div className="tech-careers-container__title__color">
            <TypewriterComponent
              options={{
                strings: ["Music.", "Delivery.", "Runaway.", "Destination."],
                autoStart: true,
                loop: true,
                cursorClassName: "tech-careers-container__cursor__color",
              }}
            />
          </div>
        </span>
        <div className="tech__subtitile">
          Technology powers <i>all</i> industries.
        </div>
        <CareerButton >
          Find your career match <img src={SparkSign} alt="Spark" />{" "}
        </CareerButton>
      </div>
      <div className="tech-careers-container__companies">
        <Carousel
          arrows={false}
          responsive={responsive}
          renderButtonGroupOutside={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
        >
          <img src={Epic} alt="Epic" />
          <img src={NBA} alt="NBA" />
          <img src={Triller} alt="Triller" />
          <img src={Channel} alt="Channel" />
          <img src={NbaAllStar} alt="NbaAllStar" />
          <img src={StyleSeat} alt="StyleSeat" />
        </Carousel>
      </div>
      <div className="tech-careers-details-box">
        {!curriculum && (
          <div className="avatarModal__container">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
            <p>Loading</p>
          </div>
        )}
        {curriculum && (
          <div className="sideBarMenu-container">
            <SideBarFilterMenu
              resetFilters={resetFilters}
              handleFilter={handleFilters}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        )}
        {curriculum && (
          <div className="tech-careers__card-box">
            {(filters.industries.length > 0 ||
              filters.brands.length > 0 ||
              filters.locations.length > 0 ||
              filters.roles.length > 0 ||
              filters.skills.length > 0) && (
              <div className="tech-careers__tag-container">
                <section>
                  {filters.industries.map((e) => (
                    <span onClick={() => handleClick(e, "industries")}>
                      {e} <CloseFilter />
                    </span>
                  ))}
                  {filters.brands.map((e) => (
                    <span onClick={() => handleClick(e, "brands")}>
                      {e} <CloseFilter />
                    </span>
                  ))}
                  {filters.locations.map((e) => (
                    <span onClick={() => handleClick(e, "locations")}>
                      {e} <CloseFilter />
                    </span>
                  ))}
                  {filters.roles.map((e) => (
                    <span onClick={() => handleClick(e, "roles")}>
                      {e} <CloseFilter />
                    </span>
                  ))}
                  {filters.skills.map((e) => (
                    <span onClick={() => handleClick(e, "skills")}>
                      {e} <CloseFilter />
                    </span>
                  ))}
                </section>
                <button onClick={resetFilters}>Reset All</button>
              </div>
            )}
            <div className="tech-careers__card-container">
              {[...Array(load).keys()].map((e) => {
                if (wishlist) {
                  const w = wishlist.find((w) => w === curriculum[e].id);
                  return (
                    <TechCareersFilterCard
                      wl={w ? true : false}
                      title={curriculum[e].career_title}
                      id={curriculum[e].id}
                      img={curriculum[e].curriculum_image}
                      setShowModal={setShowModal}
                      showModal={showModal}
                    />
                  );
                }

                return (
                  <TechCareersFilterCard
                    title={curriculum[e].career_title}
                    id={curriculum[e].id}
                    img={curriculum[e].curriculum_image}
                    setShowModal={setShowModal}
                    showModal={showModal}
                  />
                );
              })}
            </div>
            {curriculum && load !== curriculum?.length && (
              <button onClick={handleLoad}>LOAD MORE</button>
            )}
          </div>
        )}
      </div>
      <div>
        <StartJourney />
      </div>
      <div className="tech-careers__banner">
        <img src={SprinkleStar} className="sprinkle-1" alt="" />
        <img src={SprinkleStar} className="sprinkle-2" alt="" />
        <div className="tech-careers__banner-box">
          <div className="tech-careers__banner-col-1">
            <div className="tech-careers__banner-title">
              Serious about getting closer to your career in tech?
            </div>
            <div className="tech-careers__banner-text">
              Join communities to surround yourself with brilliant minds & learn
              from others.{" "}
            </div>
          </div>
          <div className="tech-careers__banner-col-2">
            <button
              onClick={() => {
                if (!localStorage.getItem("token")) {
                  setShowModal(true);
                } else {
                  navigate("/profile?scroll=communities")
                }
              }}
            >
              I'm on it <CheckboxIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechCareers;
