import axios from "axios";
import React, { useEffect, useState } from "react";
import { Arrow, CloseIcon, DropdownArrow } from "../../assets";
import Storage from "../../services/storageService";

import "./EditPreferences.scss";

const EditPreferences = ({ setShowModal, preference, setData }) => {
  const [industry, setIndustry] = useState("");
  const [brand, setBrand] = useState("");
  const [skill, setSkill] = useState("");
  const [location, setLocation] = useState();
  const [l, setL] = useState(preference.location);
  const [select, setSelect] = useState("");

  const [preferences, setPreferences] = useState(preference);

  const handlePreferences = (type, item) => {
    console.log(type, item);
    if (type === "location") {
      preferences[type] = item;
      setL(item);
      console.log(preferences);
      return;
    }
    if (preferences[type].includes(item)) {
      console.log(type, item);

      const arr = preferences[type].filter((e) => e !== item);
      preferences[type] = arr;
      console.log(preferences);
    } else {
      preferences[type].push(item);
      console.log(preferences);
    }
  };

  const handleSave = async () => {
    let pr = {
      industry: [],
      favorite_brands: [],
      locations: l,
      skills: [],
    };

    industry
      .filter((e, ind) => preferences.industry.find((n) => e[1] === n))
      .map((e) => pr.industry.push(e[0]));
    brand.records
      .filter((e, ind) =>
        preferences.brand.find((n) => e.fields.brand_name === n)
      )
      .map((e) => pr.favorite_brands.push(e.fields.id));
    skill.records
      .filter((e, ind) => preferences.skill.find((n) => e.fields.skill === n))
      .map((e) => pr.skills.push(e.fields.id));
   

    const token = await Storage.getJWTToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log(pr);

    const res = await axios.post(
      `${process.env.REACT_APP_API}/edit-preferences`,
      pr,
      config
    );
    console.log(res);
    setShowModal(false);
    const response = await axios.get(`${process.env.REACT_APP_API}/me`, config);
    setData(response.data);
  };

  useEffect(() => {
    const getPreferences = async () => {
      let res = await axios.get(`${process.env.REACT_APP_API}/industries`);
      setIndustry(res.data);
      res = await axios.get(`${process.env.REACT_APP_API}/brand`);
      setBrand(res.data);
      res = await axios.get(`${process.env.REACT_APP_API}/skills`);
      setSkill(res.data);
      res = await axios.get(`${process.env.REACT_APP_API}/locations`);
      setLocation(res.data);
      console.log(res.data);
    };
    getPreferences();
  }, []);

  return (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          setShowModal(false);
        }
      }}
      className="communityModal__container"
    >
      <div className="editPreferences">
        <CloseIcon className="close" onClick={() => setShowModal(false)} />
        <div className="editPreferences__title">Edit Preferences</div>
        <div className="editPreferences__container">
          <div className="editPreferences__box">
            <div
              onClick={() =>
                setSelect(select === "industries" ? "" : "industries")
              }
              className="editPreferences__box-title"
            >
              Industries
              <DropdownArrow className={select === "industries" && "active"} />
            </div>
            <form className={select === "industries" && "active"}>
              {industry &&
                industry.map((e) => (
                  <label>
                    <input
                      defaultChecked={preferences.industry.includes(e[1])}
                      onChange={() => handlePreferences("industry", e[1])}
                      type="checkbox"
                    />
                    {e[1]}
                  </label>
                ))}
            </form>
          </div>
          <div className="editPreferences__box">
            <div
              onClick={() => setSelect(select === "brands" ? "" : "brands")}
              className="editPreferences__box-title"
            >
              Brands
              <DropdownArrow className={select === "brands" && "active"} />
            </div>
            <form className={select === "brands" && "active"}>
              {brand &&
                brand?.records.map((e) => (
                  <label>
                    <input
                      defaultChecked={preferences.brand.includes(
                        e.fields.brand_name
                      )}
                      onChange={() =>
                        handlePreferences("brand", e.fields.brand_name)
                      }
                      type="checkbox"
                    />
                    {e.fields.brand_name}
                  </label>
                ))}
            </form>
          </div>
          <div className="editPreferences__box">
            <div
              onClick={() =>
                setSelect(select === "locations" ? "" : "locations")
              }
              className="editPreferences__box-title"
            >
              Locations{" "}
              <DropdownArrow className={select === "locations" && "active"} />
            </div>
            <form className={select === "locations" && "active"}>
              {location &&
                location?.map((e) => (
                  <label>
                    <input
                      name="location"
                      checked={l === e[1]}
                      onChange={() => handlePreferences("location", e[1])}
                      type="checkbox"
                    />
                    {e[1]}
                  </label>
                ))}
            </form>
          </div>
          <div className="editPreferences__box">
            <div
              onClick={() => setSelect(select === "skills" ? "" : "skills")}
              className="editPreferences__box-title"
            >
              Skills{" "}
              <DropdownArrow className={select === "skills" && "active"} />
            </div>
            <form className={select === "skills" && "active"}>
              {skill &&
                skill.records.map((e) => (
                  <label>
                    <input
                      defaultChecked={preferences.skill?.includes(
                        e.fields.skill
                      )}
                      onChange={() => {
                        handlePreferences("skill", e.fields.skill);
                      }}
                      type="checkbox"
                    />
                    {e.fields.skill}
                  </label>
                ))}
            </form>
          </div>
        </div>
        <button onClick={handleSave} className="editPreferences__btn">
          Save
        </button>
      </div>
    </div>
  );
};

export default EditPreferences;
