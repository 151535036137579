import React, { useState } from "react";

import "./AdminCourse.scss";
import axios from "axios";

const AdminCourse = ({
  id,
  title,
  img,
  request,
  setId,
  setType,
  setModal,
  setEdit,
  data,
}) => {
  const [menu, setMenu] = useState(false);

  const getAvatar = (url) => {
    if (url.includes("image/upload/http")) {
      const str = url.slice(13);
      return str;
    } else {
      return url;
    }
  };

  const handleDelete = async () => {
    setId(id);
    setType("courses");
    setModal(true);
  };

  console.log(data);

  return (
    <div className="adminCourse">
      <menu onClick={() => setMenu(!menu)}>
        <span></span>
        <span></span>
        <span></span>
        {menu && (
          <ul>
            <li
              onClick={() =>
                setEdit("Course", {
                  title: data.course_title,
                  image: data.course_image,
                  link: data.link_to_course,
                  buyLink: data.affiliate_link,
                  category: data.label,
                  time: data.estimated_time,
                  descrip: data.description,
                  id: data.id,
                })
              }
            >
              Edit
            </li>
            <li onClick={handleDelete}>Delete</li>
          </ul>
        )}
      </menu>
      <div className="adminCourse__img">
        {img &&
        img.includes("res.") &&
        !img.includes("localhost") &&
        !img.includes("blob") &&
        !img.includes("FormData") ? (
          <img src={getAvatar(img)} />
        ) : (
          <img
            src={
              "https://res.cloudinary.com/dsfnoazam/image/upload/v1671414705/w18bxkgwv0asljcmcxzm.png"
            }
          />
        )}
      </div>
      <div className="adminCourse__name">{title}</div>
    </div>
  );
};

export default AdminCourse;
