import axios from "axios";
import Storage from "./storageService";

const removeWishlist = async (id, type) => {
  try {
    const token = await Storage.getJWTToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let res;
    if (type === "course") {
      console.log("course");
      res = await axios.patch(
        `${process.env.REACT_APP_API}/remove-from-wishlist`,
        {
          courses: [id],
          curriculums: [],
        },
        config
      );
    } else if (type === "curriculum") {
      console.log("curriculum");
      res = await axios.patch(
        `${process.env.REACT_APP_API}/remove-from-wishlist`,
        {
          courses: [],
          curriculums: [id],
        },
        config
      );
    }
    console.log(res);
  } catch (err) {
    console.log(err);
  }
};

export default removeWishlist;
