import React, { useState } from "react";
import { CloseIcon, Star } from "../../assets";
import Storage from "../../services/storageService";

import "./NewCourseModal.scss";
import axios from "axios";

const NewCourseModal = ({ setShowModal, request }) => {
  const [link, setLink] = useState("");

  const handleClick = async () => {
    try{

      const token = await Storage.getJWTToken();
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const res = await axios.post(
        `${process.env.REACT_APP_API}/learn_list_write/`,
        {
          link: link,
        },
        config
      );
      console.log(res);
      request();
      setShowModal(false);
    } catch(err){
      // console.log(err)
      alert("Failed to add course to your learnlist. Try again.")
    }
  };

  return (
    <div
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          setShowModal(false);
        }
      }}
      className="newCourseModal__container"
    >
      <div className="newCourseModal">
        <CloseIcon className="close" onClick={() => setShowModal(false)} />
        <div className="communityModal__title">
          <span>My Learnlist</span>
          Add New Course
        </div>
        <main>
          <section>
            {/* <div className="newCourseModal__title">
              Recommended Courses <Star />
            </div>
            <div className="newCourseModal__course-container">
              <div className="newCourseModal__course-card">
                <div className="newCourseModal__course-card-title">
                  NoSQL <br /> Essential Training
                  <img
                    src={
                      "https://logos-download.com/wp-content/uploads/2021/09/LinkedIn_Learning_Logo.svg"
                    }
                    alt="Logo"
                  />
                </div>
                <div className="newCourseModal__course-card-course">
                  NoSQL Essential Training
                </div>
              </div>
              <div className="newCourseModal__course-card">
                <div className="newCourseModal__course-card-title">
                  NoSQL <br /> Essential Training
                  <img
                    src={
                      "https://logos-download.com/wp-content/uploads/2021/09/LinkedIn_Learning_Logo.svg"
                    }
                    alt="Logo"
                  />
                </div>
                <div className="newCourseModal__course-card-course">
                  NoSQL Essential Training
                </div>
              </div>
              <div className="newCourseModal__course-card">
                <div className="newCourseModal__course-card-title">
                  NoSQL <br /> Essential Training
                  <img
                    src={
                      "https://logos-download.com/wp-content/uploads/2021/09/LinkedIn_Learning_Logo.svg"
                    }
                    alt="Logo"
                  />
                </div>
                <div className="newCourseModal__course-card-course">
                  NoSQL Essential Training
                </div>
              </div>
            </div> */}
            <div className="newCourseModal__add-container">
              <form>
                <input
                  onChange={(e) => setLink(e.target.value)}
                  type="text"
                  placeholder="Enter Link"
                  value={link}
                />
                <button onClick={handleClick}>Add New</button>
              </form>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default NewCourseModal;
