import React, { useEffect, useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";

import "./CourseEnrollCard.scss";
import { useNavigate } from "react-router-dom";
import addWishlist from "../../services/addWishlist";
import removeWishlist from "../../services/removeWishlist";
import axios from "axios";

import ReactGA from "react-ga";

const CourseEnrollCard = ({
  big,
  title,
  id,
  wl,
  img,
  link,
  setShowLoginModal,
  showModal,
  profile,
}) => {
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(wl);
  const [ipAddress, setIpAddress] = useState(null);

  const handleChange = (e) => {
    setWishlist(e.target.checked);
    if (e.target.checked) {
      addWishlist(id, "course");
    } else {
      removeWishlist(id, "course");
    }
  };
  const getImage = (url) => {
    if (url.includes("image/upload/http")) {
      const str = url?.slice(13);
      return str;
    } else {
      return url;
    }
  };

  const getIP = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIpAddress(res.data.ip);
  };

  useEffect(() => {
    getIP();
    setWishlist(wl);
  }, [wl]);

  return (
    <div className={`courseEnrollCard ${big ? "big" : ""}`}>
      <div className="courseEnrollCard__favorite">
        <Checkbox
          sx={{
            color: "#FF5A79",
            "&.Mui-checked": {
              color: "#FF5A79",
            },
          }}
          checked={wishlist}
          onChange={handleChange}
          onClick={(e) => {
            if (!localStorage.getItem("token")) {
              e.target.checked = false;
              setShowLoginModal(true);
            }
          }}
          icon={<FavoriteBorder />}
          checkedIcon={<Favorite />}
        />
      </div>
      <img src={getImage(img)} className="courseEnrollCard__head" alt="Course">
        {/* <div className="courseEnrollCard__head-text">{title}</div> */}
        {/* <img
          src={
            "https://logos-download.com/wp-content/uploads/2021/09/LinkedIn_Learning_Logo.svg"
          }
          alt="Logo"
        /> */}
      </img>
      <div className="courseEnrollCard__body">
        <span>{title}</span>
        <div>
          {ipAddress && (
            <a
              target={"_blank"}
              onClick={() => {
                ReactGA.event({
                  category: "Affiliate link click",
                  action: `${profile.uuid} clicked on ${id} course enroll button.`,
                  label: "Clicking on course enroll button.",
                  value: id,
                });
              }}
              style={{ textDecoration: "none" }}
              href={`${
                !link.includes("http") ? "https://" : ""
              }${link}?subId1=${
                profile.uuid
              }&subId2=${id}&subId3=${ipAddress}&subId4=${""}
                          `}
              rel={"noreferrer"}
            >
              <button>Enroll</button>
            </a>
          )}
          <button onClick={() => navigate(`/course-details/${id}`)}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseEnrollCard;
