import React, { useRef, useEffect } from "react";
import Storage from "../../services/storageService";
import axios from "axios";

export default function MyLandbot({ url }) {
  const containerRef = useRef(null);
  let _landbot;

  const startLandbot = async () => {
    const token = await Storage.getJWTToken();
    let data;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.get(`${process.env.REACT_APP_API}/me`, config);
    data = res.data;

    // eslint-disable-next-line no-undef
    _landbot = new Landbot.Container({
      container: containerRef.current,
      configUrl: url,
      customData: {
        email: data.email,
        name: data.username,
        auth_provider: data.auth_provider,
      },
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      startLandbot();
    } else {
      // eslint-disable-next-line no-undef
      _landbot = new Landbot.Container({
        container: containerRef.current,
        configUrl: url,
      });
    }

    return () => _landbot.destroy();
  }, [url, containerRef]);

  return <div className="MyLandbot" ref={containerRef} />;
}
