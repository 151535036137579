import React, { useEffect, useState } from "react";
import { Books, Eye, Home, Logo, Plus, Prefences } from "../../assets";
import {
  AdminCurriculum,
  CourseEnrollCard,
  DeleteModal,
  LearnList,
} from "../../components";

import "./Admin.scss";
import axios from "axios";
import AdminCourse from "../../components/AdminCourse/AdminCourse";
import AddNewCourse from "../../components/AddNewCourse/AddNewCourse";
import AddNewCurriculum from "../../components/AddNewCurriculum/AddNewCurriculum";
import EditModal from "../../components/EditModal/EditModal";

const Admin = () => {
  const [addCourse, setAddCourse] = useState(false);
  const [addCurriculum, setAddCurriculum] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [select, setSelect] = useState("home");
  const [curriculums, setCurriculums] = useState(null);
  const [courses, setCourses] = useState(null);
  const [deleteId, setDeleteId] = useState(false);
  const [type, setType] = useState("");
  const [editType, setEditType] = useState("Curriculum");
  const [editData, setEditData] = useState(null);
  const [editID, setEditID] = useState(null);

  const getCurriculums = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API}/curriculum`);
    setCurriculums(res.data);
  };
  const getCourses = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API}/courses`);
    setCourses(res.data);
  };

  useEffect(() => {
    getCourses();
    getCurriculums();
  }, []);

  // const [showAddCourse]

  const handleEdit = (type, data, id) => {
    setEditType(type);
    setEditData(data);
    setEdit(true);
    console.log(data)
  };

  return (
    <>
      {deleteModal && (
        <DeleteModal
          setShowModal={setDeleteModal}
          deleteId={deleteId}
          type={type}
          request={type === "curriculum" ? getCurriculums : getCourses}
        />
      )}
      {addCourse && <AddNewCourse setShowModal={setAddCourse} />}
      {addCurriculum && <AddNewCurriculum setShowModal={setAddCurriculum} />}
      {edit && (
        <EditModal setShowModal={setEdit} type={editType} data={editData} />
      )}
      <div className="admin">
        <nav>
          <img src={Logo} alt="Logo" />

          <div className="admin__profile-pic">UI</div>
        </nav>
        <main>
          <div className="admin__navbar">
            <ul>
              <li
                className={`${select === "home" ? "active" : ""}`}
                onClick={() => setSelect("home")}
              >
                <Home /> Home
              </li>
              <li
                className={`${select === "curriculum" ? "active" : ""}`}
                onClick={() => setSelect("curriculum")}
              >
                <Books /> Curriculum
              </li>
              <li
                className={`${select === "courses" ? "active" : ""}`}
                onClick={() => setSelect("courses")}
              >
                <Prefences /> Courses
              </li>
            </ul>
          </div>
          {select === "home" && (
            <div className="admin__main">
              <div className="admin__analytics">
                <div className="admin__title">Analytics</div>
                <div className="admin__analytics-container">
                  <div className="admin__analytics-box">
                    <menu>12% &#8593;</menu>
                    {curriculums && curriculums.length}
                    <span>Total Curriculum</span>
                  </div>
                  <div className="admin__analytics-box">
                    <menu>10% &#8593;</menu>
                    {courses && courses.length}
                    <span>Total Courses</span>
                  </div>
                  <div className="admin__analytics-box">
                    <menu>18% &#8595;</menu>
                    200
                    <span>Total Learners</span>
                  </div>
                </div>
              </div>
              <div className="admin__curriculums">
                <div className="admin__title-box">
                  <div className="admin__title">Recent Curriculums</div>
                  <button onClick={() => setSelect("curriculum")}>
                    {" "}
                    <Eye /> View All
                  </button>
                </div>
                <div className="admin__curriculums-container">
                  {curriculums &&
                    [...Array(4).keys()].map((e) => (
                      <AdminCurriculum
                        setModal={setDeleteModal}
                        setId={setDeleteId}
                        setType={setType}
                        data={e}
                        name={curriculums[e].career_title}
                        id={curriculums[e].id}
                        setEdit={handleEdit}
                        request={getCurriculums}
                        img={curriculums[e].curriculum_image}
                      />
                    ))}
                </div>
              </div>
              <div className="admin__courses">
                <div className="admin__title-box">
                  <div className="admin__title">Recent Course</div>
                  <button onClick={() => setSelect("courses")}>
                    <Eye /> View All
                  </button>
                </div>
                <div className="admin__courses-container">
                  {courses &&
                    [...Array(4).keys()].map((e) => (
                      <AdminCourse
                        setModal={setDeleteModal}
                        setId={setDeleteId}
                        setType={setType}
                        request={getCourses}
                        img={courses[e].course_image}
                        data={courses[e]}
                        setEdit={handleEdit}
                        title={courses[e].course_title}
                        id={courses[e].id}
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
          {select === "curriculum" && (
            <div className="admin__main">
              <div className="admin__curriculums-more">
                <div className="admin__title-box">
                  <div className="admin__title">Curriculums</div>
                  <button onClick={() => setAddCurriculum(true)}>
                    <Plus /> Add Curriculum
                  </button>
                </div>
                <div className="admin__curriculums-more-container">
                  {curriculums &&
                    curriculums.map((e) => (
                      <AdminCurriculum
                        setModal={setDeleteModal}
                        setId={setDeleteId}
                        setType={setType}
                        name={e.career_title}
                        request={getCurriculums}
                        setEdit={handleEdit}
                        id={e.id}
                        data={e}
                        img={e.curriculum_image}
                      />
                    ))}
                  <div className="admin__curriculums-more-add">
                    <span>+</span>
                    Add New
                  </div>
                </div>
              </div>
            </div>
          )}
          {select === "courses" && (
            <div className="admin__main">
              <div className="admin__curriculums-more">
                <div className="admin__title-box">
                  <div className="admin__title">Courses</div>
                  <button onClick={() => setAddCourse(true)}>
                    <Plus /> Add Courses
                  </button>
                </div>
                <div className="admin__curriculums-more-container">
                  {courses &&
                    courses.map((e) => (
                      <AdminCourse
                        setModal={setDeleteModal}
                        setId={setDeleteId}
                        setType={setType}
                        request={getCourses}
                        setEdit={handleEdit}
                        img={e.course_image}
                        title={e.course_title}
                        id={e.id}
                        data={e}
                      />
                    ))}
                  <div
                    onClick={() => setAddCourse(true)}
                    className="admin__curriculums-more-add"
                  >
                    <span>+</span>
                    Add New
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default Admin;
