import axios from "axios";
let tokenKey = "token";
let userEmailKey = "email";
const clearJWTToken = () => {
  localStorage.removeItem(tokenKey);
};

const setJWTToken = (token) => {
  localStorage.setItem(tokenKey, JSON.stringify({ ...token }));
};

const clearStorage = () => {
  localStorage.clear();
};

const getJWTToken = async () => {
  try {
    if (!localStorage.getItem("token")) {
      return;
    }
    let t = JSON.parse(localStorage.getItem("token")).access;
    const res = await axios.post(`${process.env.REACT_APP_API}/token/verify`, {
      token: t,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${t}`,
      },
    };

    return t;
  } catch (err) {
    try {
      console.log(err);
      let t = JSON.parse(localStorage.getItem("token")).refresh;
      if (err.response.status === 401) {
        const res = await axios.post(
          `${process.env.REACT_APP_API}/token/refresh`,
          {
            refresh: t,
          }
        );
        localStorage.setItem(
          "token",
          JSON.stringify({
            access: res.data.access,
            refresh: t,
          })
        );
        return res.data.access;
      }
    } catch (err) {
      if (err.response.status === 401) {
        localStorage.removeItem("token");
      }
      return;
    }
  }
};

const getUserEmail = () => {
  return localStorage.getItem(userEmailKey);
};

export default {
  clearStorage,
  setJWTToken,
  clearJWTToken,
  getJWTToken,
  getUserEmail,
};
