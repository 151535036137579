/* eslint-disable jsx-a11y/anchor-is-valid */
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Rocket, Star } from "../../assets";
import { CourseEnrollCard } from "../../components";
import addWishlist from "../../services/addWishlist";
import removeWishlist from "../../services/removeWishlist";
import Storage from "../../services/storageService";
import ReactGA from "react-ga";

import "./CourseDetails.scss";

const CourseDetails = ({ setShowLoginModal, profile }) => {
  const { id } = useParams();
  const [data, setData] = useState("");
  const [wishlist, setWishlist] = useState(false);
  const [relatedCourses, setRelatedCourses] = useState([]);
  const [like, setLike] = useState([]);
  const [ipAddress, setIpAddress] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    const token = await Storage.getJWTToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(`${process.env.REACT_APP_API}/courses/${id}`);
    setData(res.data);

    let response;
    if (token) {
      response = await axios.get(
        `${process.env.REACT_APP_API}/wishlist-read`,
        config
      );
    }

    if (response?.data.length > 0) {
      setLike(response.data[0].courses);
      response.data[0].courses.find((e) => e === res.data.id) &&
        setWishlist(true);
    }
    await getIP();
    const course = await axios.get(
      `${process.env.REACT_APP_API}/Curriculum-courses/related_courses/?course_id=${res.data.id}`
    );
    console.log(course);
    setRelatedCourses(course.data);
  };

  const getIP = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIpAddress(res.data.ip);
  };
  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const handleChange = (e) => {
    setWishlist(e.target.checked);
    if (e.target.checked) {
      addWishlist(id, "course");
    } else {
      removeWishlist(id, "course");
    }
  };
  const getImage = (url) => {
    if (url.includes("image/upload/http")) {
      const str = url.slice(13);
      return str;
    } else {
      return url;
    }
  };

  const usePrevLocation = (location) => {
    const prevLocRef = useRef(location);

    useEffect(() => {
      prevLocRef.current = location;
    }, [location]);

    return prevLocRef.current;
  };

  const location = useLocation();

  const prevLocation = usePrevLocation(location);
  console.log(prevLocation);

  console.log(navigate);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <div className="breadCrumbs">
        <div>
          <Link to="/tech-careers">Browse Tech Careers</Link>
          {">"}
        </div>
        <div onClick={() => navigate(-1)}>
          <Link>Curriculum</Link>
          {">"}
        </div>
        <div>
          <Link>Course</Link>
        </div>
      </div>
      <div className="courseDetails">
        {data ? (
          <>
            <div className="courseDetails__course">
              <div className="courseDetails__course-container">
                <div
                  style={{ background: `url(${getImage(data.course_image)})` }}
                  className="courseDetails__course-box"
                >
                  <div className="courseEnrollCard__favorite">
                    <Checkbox
                      sx={{
                        color: "#FF5A79",
                        "&.Mui-checked": {
                          color: "#FF5A79",
                        },
                      }}
                      checked={wishlist}
                      onChange={handleChange}
                      onClick={(e) => {
                        if (!localStorage.getItem("token")) {
                          e.target.checked = false;
                          setShowLoginModal(true);
                        }
                      }}
                      icon={<FavoriteBorder />}
                      checkedIcon={<Favorite />}
                    />
                  </div>
                </div>
                <div className="courseDetails__details">
                  <div className="courseDetails__details-col-1">
                    {data && (
                      <div className="courseDetails__details-title">
                        {data.course_title}
                      </div>
                    )}
                    <div className="courseDetails__details-btn">
                      {data && (
                        <>
                          <a
                            onClick={() => {
                              ReactGA.event({
                                category: "Affiliate link click",
                                action: `${profile.uuid} clicked on ${id} course enroll button.`,
                                label: "Clicking on course enroll button.",
                                value: id,
                              });
                            }}
                            target={"_blank"}
                            style={{ textDecoration: "none" }}
                            href={`${
                              !data.affiliate_link.includes("http")
                                ? "https://"
                                : ""
                            }${data.affiliate_link}?subId1=${
                              profile.uuid
                            }&subId2=${id}&subId3=${ipAddress}&subId4=${""}
                          `}
                            rel={"noreferrer"}
                          >
                            <button>Enroll</button>
                          </a>
                          {/* <a
                      target={"_blank"}
                      style={{ textDecoration: "none" }}
                      href={data.affiliate_link}
                      rel={"noreferrer"}
                    >
                      <button>
                        Learn More <img src={Rocket} alt="" />{" "}
                      </button>
                    </a> */}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="courseDetails__details-col-2">
                    <div className="courseDetails__details-tag">
                      Category <span>Skills</span>
                    </div>
                    <div className="courseDetails__details-tag">
                      Estimated Time{" "}
                      {data && <span>{data?.estimated_time}</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="courseDetails__descrip">
                <div>Description: 💫</div>
                {data && <p>{data.description}</p>}
              </div>
            </div>
            <div className="courseDetails__related">
              <div className="courseDetails__related-title">
                Related Courses
              </div>
              <div className="courseDetails__related-container">
                {relatedCourses.length > 0 &&
                  relatedCourses.map((e) => {
                    const w = like.find((w) => w === e.id);
                    return (
                      <CourseEnrollCard
                        img={e.course_image}
                        wl={w}
                        id={e.id}
                        link={e.affiliate_link}
                        title={e.course_title}
                        setShowLoginModal={setShowLoginModal}
                        profile={profile}
                      />
                    );
                  })}
              </div>
            </div>
          </>
        ) : (
          <div className="avatarModal__container">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
            <p>Loading</p>
          </div>
        )}
      </div>
    </>
  );
};

export default CourseDetails;
