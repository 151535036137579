import axios from "axios";
import Storage from "./storageService";

const getWishlist = async () => {
  const token = await Storage.getJWTToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  if (token) {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/wishlist-read`,
      config
    );
    return response.data[0];
  }
  return [];
};

export default getWishlist;
