/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useNavigate } from "react-router-dom";
import {
  CloseIcon,
  GmailLogin,
  Hidden,
  InfinityBlackLogo,
  LinkedInLogin,
  Logo,
} from "../../assets";
import Storage from "../../services/storageService";

import { useLinkedIn } from "react-linkedin-login-oauth2";
// You can use provided image shipped by this package or using your own
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";

import "./AuthModal.scss";

const AuthModal = ({ setShowModal, callProfile }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidden, setHidden] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    try {
      setError(false);
      const res = await axios.post(`${process.env.REACT_APP_API}/login`, {
        username: email,
        password: password,
      });
      Storage.setJWTToken(res.data?.token);
      localStorage.setItem("email", res?.data?.user?.email);
      setEmail("");
      setPassword("");
      setShowModal(false);
      callProfile();
      // handleSnackbar("You are successfully logged in!", "success");
    } catch (err) {
      setError(err.response.data.detail);
      console.log(err);
      // setError("Email/Password is incorrect!");
      // setError(err.response.data.detail);

      // handleSnackbar("Email/Password is incorrect!", "error")
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "78u7mxzmcasrcg",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "r_emailaddress r_liteprofile w_member_social",
    onSuccess: async (code) => {
      console.log(code);
      try {
        const res = await axios.post(
          `https://secure-answer-368913.uc.r.appspot.com/social-auth/linkedin/`,
          {
            auth_token: code,
          }
        );
        Storage.setJWTToken(res.data?.tokens[0].token);
        localStorage.setItem("email", res?.data?.user?.email);
        setShowModal(false);
        callProfile();
      } catch (err) {
        console.log(err);
        setError(err.response.data.detail);
      }
      // handleSnackbar("You are successfully logged in!", "success");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackbar = (sentence, variant = "success") => {
    enqueueSnackbar(sentence, { variant: variant });
  };

  const handleCredentialResponse = async (response) => {
    console.log("Encoded JWT ID token: " + response.credential);
    try {
      const res = await axios.post(
        `https://secure-answer-368913.uc.r.appspot.com/social-auth/google/`,
        {
          auth_token: response.credential,
        }
      );
      Storage.setJWTToken(res.data?.tokens[0].token);
      localStorage.setItem("email", res?.data?.user?.email);
      setShowModal(false);
      callProfile();
    } catch (err) {
      console.log(err);
      setError(err.response.data.detail);
    }
    // handleSnackbar("You are successfully logged in!", "success");
  };
  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        "515747735534-qc73hf0iv1r0pmur2gmgckcaqdck0lgl.apps.googleusercontent.com",
      callback: handleCredentialResponse,
      login_uri: window.location.origin,
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "large" } // customization attributes
    );
  });

  const handleForget = async (e) => {
    e.preventDefault();
    if (email && email.includes("@") && email.includes(".")) {
      if (!disable) {
        try {
          setDisable(true)
          const res = await axios.post(
            `https://secure-answer-368913.uc.r.appspot.com/api/v1/auth/password-reset`,
            {
              email: email,
            }
          );
          setTimeout(() => {setDisable(false)}, 5000);
          console.log(res);
          if (res.status === 200) {
            setError("Kindly check your email to reset your password!");
          }

        } catch (err) {
          console.log(err);
          setError(err.response.data.email[0]);
        }
      }
    } else {
      setError("Provide email to reset your password!");
    }
  };

  return (
    <div
      className="authModal__container"
      onClick={(event) => {
        // event.preventDefault();
        if (event.target === event.currentTarget) {
          setShowModal(false);
        }
      }}
    >
      <div className="authModal">
        <CloseIcon className="close" onClick={() => setShowModal(false)} />
        <img src={InfinityBlackLogo} alt="Logo" className="logo" />
        {/* <div className="authModal__title">Login</div> */}
        <form onSubmit={(e) => login(e)} className="form">
          <input
            className="authModal__input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email address"
            required
          />
          <div>
            <input
              className="authModal__input password"
              type={hidden ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <img src={Hidden} onClick={() => setHidden(!hidden)} alt="" />
          </div>
          {error &&
            (error === "Kindly check your email to reset your password!" ? (
              <p className="authModal__error-message success">{error}</p>
            ) : (
              <p className="authModal__error-message">{error}</p>
            ))}
          <div className="form__col">
            <label>
              <input checked={true} type="checkbox" /> Keep me logged in
            </label>
            <a
              onClick={(e) => {
                handleForget(e);
              }}
            >
              Forgot password?
            </a>
          </div>
          <button type="submit">Login</button>
        </form>
        <div className="authModal__text">
          <span>&#8212;</span>
          Or Login with
          <span>&#8212;</span>
        </div>
        <div className="authModal__btn-container">
          <div id="buttonDiv"></div>
          {/* <button onClick={openGoogleLoginPage}>
            <GmailLogin /> Gmail
          </button> */}
          {/* <button onClick={handleLinkedIn}>
            <LinkedInLogin /> LinkedIn
          </button> */}
          <img
            onClick={linkedInLogin}
            src={linkedin}
            alt="Sign in with Linked In"
            style={{ maxWidth: "180px", cursor: "pointer" }}
          />
        </div>
        <div
          style={{ cursor: "pointer" }}
          className="authModal__signup"
          onClick={() => {
            setShowModal(false);
            navigate("/career-finder");
          }}
        >
          {/* Don’t have an account?{" "} */}
          <span>Sign Up</span>
          &nbsp;via our Career Finder
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
