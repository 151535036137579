import React, { useEffect, useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { CreativeDesignNBA } from "../../assets";

import "./TechCareersFilterCard.scss";
import { useNavigate, useParams } from "react-router-dom";
import addWishlist from "../../services/addWishlist";
import removeWishlist from "../../services/removeWishlist";

const TechCareersFilterCard = ({ title, id, wl, img, setShowModal }) => {
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(wl);
  console.log(wishlist);

  const handleChange = (e) => {
    setWishlist(e.target.checked);
    if (e.target.checked) {
      addWishlist(id, "curriculum");
    } else {
      removeWishlist(id, "curriculum");
    }
  };

  const getImage = (url) => {
    if (url.includes("image/upload/http")) {
      const str = url.slice(13);
      return str;
    } else {
      return url;
    }
  };

  useEffect(() => {
    setWishlist(wl);
  }, [wl]);

  return (
    <div className="tech-careers-filter-card-container">
      <div>
        <img
          onClick={() => navigate(`/curriculum-overview/${id}`)}
          src={getImage(img)}
          alt="Logo"
          style={{ width: "100%" }}
        />
        <div className="tech-careers-card-favourite">
          <Checkbox
            sx={{
              color: "#FF5A79",
              "&.Mui-checked": {
                color: "#FF5A79",
              },
            }}
            checked={wishlist}
            onChange={handleChange}
            onClick={(e) => {
              if (!localStorage.getItem("token")) {
                e.target.checked = false;
                setShowModal(true);
              }
            }}
            icon={<FavoriteBorder />}
            checkedIcon={<Favorite />}
          />
        </div>
      </div>
      <div
        onClick={() => navigate(`/curriculum-overview/${id}`)}
        className="tech-careers-card-text"
      >
        {title}
      </div>
    </div>
  );
};

export default TechCareersFilterCard;
