import React from "react";

import "./JobCard.scss";

const JobCard = ({ img, job, place }) => {
  const getImage = (url) => {
    if (url.includes("image/upload/http")) {
      const str = url.slice(13);
      return str;
    } else {
      return url
    }
  };

  return (
    <div className="jobcard">
      <img
        src={img.includes("image/upload") ? getImage(img) : img}
        alt={place}
      />
      <div>{job}</div>
      <p>{place}</p>
    </div>
  );
};

export default JobCard;
