import React, { useEffect, useState } from "react";

import "./EditProfile.scss";
import { useNavigate, useNavigation } from "react-router-dom";
import axios from "axios";
import Storage from "../../services/storageService";
import { DeleteModal } from "../../components";
import ReactGA from "react-ga";


const EditProfile = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOnFirstNameChange = (event) => setFirstName(event.target.value);
  const handleOnLasttNameChange = (event) => setLastName(event.target.value);
  const handleOnPasswordChange = (event) => setPassword(event.target.value);
  const handleOnConfirmPasswordChange = (event) =>
    setConfirmPassword(event.target.value);

  useEffect(() => {
    const getData = async () => {
      const token = await Storage.getJWTToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(`${process.env.REACT_APP_API}/me`, config);
      setData(res.data);
      setFirstName(res.data.first_name);
      setLastName(res.data.last_name);
    };
    getData();
  }, []);

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const token = await Storage.getJWTToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (firstName !== data.first_name || lastName !== data.last_name) {
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_API}/update-user`,
          {
            username: data.username,
            first_name: firstName,
            last_name: lastName,
          },
          config
        );
        console.log(res);
        if (res.status === 200) {
          setMessage("Your name has been successfully changed.");
        }
      } catch (err) {
        alert("Failed to change your name. Kindly try again!");
      }
    }

    if (password || confirmPassword) {
      if (password !== confirmPassword) {
        return alert("Make sure that both the passwords match!");
      } else {
        try {
          const token = await Storage.getJWTToken();
          const res = await axios.post(
            `${process.env.REACT_APP_API}/update-password`,
            {
              password: password,
              token: token,
            }
          );
          console.log(res.data);
          setMessage("Your password has been successfully changed.");
        } catch (err) {
          alert("Failed to change your password. Kindly try again!");

          console.log(err);
        }
      }
    }
  };

  const handleChangeEmail = async () => {
    try {
      const token = await Storage.getJWTToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios.patch(
        `${process.env.REACT_APP_API}/update-user`,
        // `https://30b4-39-59-106-33.in.ngrok.io/api/v1/update-user`,
        {
          email: data.email,
          username: data.username,
        },
        config
      );
      console.log(res);
      setMessage(
        `Email verfication sent to ${data.email}. Verify it to change your email.`
      );
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  return (
    <>
      {showModal && <DeleteModal deleteId={data.uuid}  setShowModal={setShowModal} />}
      <a href="/profile" className="edit-back-button">
        <span>{"<"}</span>
        Back
      </a>
      <div className="edit-profile-route">
        <div className="modal">
          <h2>edit profile</h2>
          <form className="edit-profile-form" onSubmit={handleOnSubmit}>
            <div className="name-div">
              <div className="input-wrapper">
                <label htmlFor="first-name">First name: </label>
                <input
                  type={"text"}
                  id="first-name"
                  onChange={handleOnFirstNameChange}
                  placeholder="Enter first name"
                  value={firstName}
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="last-name">Last name: </label>
                <input
                  type={"text"}
                  id="last-name"
                  onChange={handleOnLasttNameChange}
                  placeholder="Enter last name"
                  value={lastName}
                />
              </div>
            </div>
            <div className="input-wrapper">
              <label htmlFor="new-password">New password: </label>
              <input
                type={"password"}
                id="new-password"
                onChange={handleOnPasswordChange}
                placeholder="Enter new password"
                value={password}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="confirm-new-password">
                Confirm new password:
              </label>
              <input
                type={"password"}
                id="confirm-new-password"
                onChange={handleOnConfirmPasswordChange}
                placeholder="Confirm new password"
                value={confirmPassword}
              />
            </div>
            <span onClick={handleChangeEmail}>Change email</span>
            <span className="delete" onClick={() => setShowModal(true)}>
              Delete your account
            </span>
            {message && <p>{message}</p>}
            <button type="submit">Update</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
