import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { PointDown } from "../../assets";

import "./SideBarFilterMenu.scss";
import axios from "axios";
import { linkClasses } from "@mui/material";
import CareerButton from "../CareerButton/CareerButton";

const SideBarFilterMenu = ({
  filters,
  setFilters,
  handleFilter,
  resetFilters,
}) => {
  const [brands, setBrands] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [locations, setLocations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [skills, setSkills] = useState(null);

  const getData = async () => {
    try {
      const b = await axios.get(`${process.env.REACT_APP_API}/brands`);
      setBrands(b.data);
      const i = await axios.get(`${process.env.REACT_APP_API}/industries`);
      setIndustries(i.data);
      const l = await axios.get(`${process.env.REACT_APP_API}/locations`);
      setLocations(l.data);
      const r = await axios.get(`${process.env.REACT_APP_API}/roles`);
      setRoles(r.data);
      const s = await axios.get(`${process.env.REACT_APP_API}/skills`);
      console.log(s);
      setSkills(s.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = (e, type, value) => {
    if (e.target.checked) {
      filters[type].push(value);
      setFilters({ ...filters });
    } else {
      const data = filters[type].filter((e) => e !== value);
      setFilters({ ...filters, [type]: data });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="accordian-container">
        <div className="accordian-title">
          LEARN THE SKILLS YOU NEED <img src={PointDown} alt="PointDown" />
        </div>
        <Accordion disableGutters={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="filter-menu-options"
          >
            <div className="accordian-options-title">Industries</div>
          </AccordionSummary>
          <AccordionDetails>
            {industries.length > 1 &&
              industries.map((name) => (
                <MenuItem key={name[0]} value={name[1]}>
                  <Checkbox
                    checked={filters.industries.includes(name[1])}
                    onClick={(e) => handleClick(e, "industries", name[1])}
                  />
                  <div className="accordian-options-text">{name[1]}</div>
                </MenuItem>
              ))}
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="filter-menu-options"
          >
            <div className="accordian-options-title">Brands</div>
          </AccordionSummary>
          <AccordionDetails>
            {brands.length > 0 &&
              brands.map((name) => (
                <MenuItem key={name[0]} value={name[1]}>
                  <Checkbox
                    checked={filters.brands.includes(name[1])}
                    onClick={(e) => handleClick(e, "brands", name[1])}
                  />
                  <div className="accordian-options-text">{name[1]}</div>
                </MenuItem>
              ))}
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            className="filter-menu-options"
          >
            <div className="accordian-options-title">Locations</div>
          </AccordionSummary>
          <AccordionDetails>
            {locations.length > 0 &&
              locations.map((name) => (
                <MenuItem key={name[0]} value={name[1]}>
                  <Checkbox
                    checked={filters.locations.includes(name[1])}
                    onClick={(e) => handleClick(e, "locations", name[1])}
                  />
                  <div className="accordian-options-text">{name[1]}</div>
                </MenuItem>
              ))}
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
            className="filter-menu-options"
          >
            <div className="accordian-options-title">Roles</div>
          </AccordionSummary>
          <AccordionDetails>
            {roles.length > 0 &&
              roles.map((name) => (
                <MenuItem key={name[0]} value={name[1]}>
                  <Checkbox
                    checked={filters.roles.includes(name[1])}
                    onClick={(e) => handleClick(e, "roles", name[1])}
                  />
                  <div className="accordian-options-text">{name[1]}</div>
                </MenuItem>
              ))}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            className="filter-menu-options"
          >
            <div className="accordian-options-title">Skills</div>
          </AccordionSummary>
          <AccordionDetails>
            {skills &&
              skills.records.map((name) => (
                <MenuItem key={name.fields.id} value={name.fields.skill}>
                  <Checkbox
                    checked={filters.skills.includes(name.fields.skill)}
                    onClick={(e) => handleClick(e, "skills", name.fields.skill)}
                  />
                  <div className="accordian-options-text">
                    {name.fields.skill}
                  </div>
                </MenuItem>
              ))}
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="filter-btn">
        {/* <button
          onClick={resetFilters
            
          }
        >
          Reset
        </button> */}
        <button onClick={handleFilter}>Filter</button>
      </div>
    </>
  );
};

export default SideBarFilterMenu;
