import React from "react";
import { CloseIcon, Spark, Sparky, Star } from "../../assets";

import "./OverviewModal.scss";

const OverviewModal = ({ setShowModal, title, response, skills }) => {
  return (
    <div
      className="overviewModal__container"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          setShowModal(false);
        }
      }}
    >
      <div className="overviewModal">
        <CloseIcon className="close" onClick={() => setShowModal(false)} />
        <div className="overviewModal__header">{title}</div>
        <div className="overviewModal__body">
          <div className="overviewModal__card">
            <div className="overviewModal__title">
              Responsibilities: 💫
            </div>
            <ul>
              {response &&
                response.map(
                  (e) =>
                    e !== "" && (
                      <li>
                        <img src={Spark} alt="spark" /> {e}
                      </li>
                    )
                )}
            </ul>
            <div className="overviewModal__title">
              Curriculum Requirements: <Sparky />
            </div>
            <div className="overviewModal__tags">
              {skills && skills.map((e) => <span>{e.skill}</span>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewModal;
