import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../assets";
import CourseEnrollCard from "../CourseEnrollCard/CourseEnrollCard";
import Storage from "../../services/storageService";

import "./CommunityModal.scss";
import axios from "axios";

const CommunityModal = ({ setShowModal, request }) => {
  const [link, setLink] = useState("");
  const [defaultCommunities, setDefaultCommunities] = useState(null);

  const handleClick = async (selectedLink) => {
    const token = await Storage.getJWTToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_API}/add-community`,
      {
        link: selectedLink ? selectedLink : link,
      },
      config
    );
    console.log(res);
    request();
    setShowModal(false);
  };

  const getDefaultCommunites = async () => {
    const token = await Storage.getJWTToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const defaultCom = await axios.get(
      `${process.env.REACT_APP_API}/communities`
    );

    const com = await axios.get(
      `${process.env.REACT_APP_API}/user-communities`,
      config
    );

    console.log(defaultCom.data);
    console.log(com.data);

    let arr = [];

    defaultCom.data.map((e) => {
      const data = com.data.find((j) => j.community.id === e.id);
      if (!data) {
        arr.push(e);
      }
    });

    setDefaultCommunities(arr);
  };

  useEffect(() => {
    getDefaultCommunites();
  }, []);

  return (
    <div
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          setShowModal(false);
        }
      }}
      className="communityModal__container"
    >
      <div className="communityModal">
        <CloseIcon className="close" onClick={() => setShowModal(false)} />
        <div className="communityModal__title">
          <span>Community</span>
          Add New Community
        </div>
        <main>
          <section>
            <div className="communityModal__section-title">Community 💫</div>
            <div className="communityModal__section-container">
              <ul>
                {defaultCommunities?.length ? (
                  <div>Recommended communities</div>
                ) : (
                  ""
                )}
                <div className="break"></div>
                {defaultCommunities &&
                  defaultCommunities.map((e) => (
                    <div className="profile__communities-box">
                      <div
                        className="profile__communities-box-add"
                        onClick={() => handleClick(e.link)}
                      >
                        &#x2b;
                      </div>
                      <a href={""}>
                        <div
                          className="profile__communities-background"
                          style={{
                            backgroundImage: `url(${
                              e.logo
                                ? e?.logo?.slice(13)
                                : "https://png.pngtree.com/png-vector/20190820/ourlarge/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                            }
                          )`,
                          }}
                        ></div>
                        <div className="profile__communities-name">
                          {e?.name}
                        </div>
                      </a>
                    </div>
                    // <li>
                    //   {e.name}{" "}
                    //   <span onClick={() => handleClick(e.link)}>Add</span>
                    // </li>
                  ))}
              </ul>

              <p>Add the link to any career community you’re a part of.</p>
              <div className="newCourseModal__add-container">
                <form>
                  <input
                    onChange={(e) => setLink(e.target.value)}
                    type="text"
                    placeholder="Enter Link"
                    value={link}
                  />
                  <button onClick={() => handleClick()}>Add</button>
                </form>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default CommunityModal;
