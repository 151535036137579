import React from 'react'
import {
    Microscope,
    LightSpark,
    Laptop,
    SprinkleStar
} from '../../assets'
import './StartJourney.scss'

const StartJourney = () => {
    return (
        <section className='journey-container'>
            <img src={SprinkleStar} className={"sprinkle-star"}/>
            <div className='journey-title'>Get Started</div>
            <div className='journey-text'>Your journey starts here.</div>
            <div className='journey-subtitle'>We streamline tech career exploration & training.</div>
            <div className='get-started__container'>

                <div className='get-started__box'
                >
                    <img src={Microscope} style={{ height: "55px", width: "55px" }} />
                    <div className='get-started-options'>Explore</div>
                    <div className='get-started-options-text'>Get matched to your dream career based on your passions and interests.</div>
                </div>
                <div className='get-started__box'
                >
                    <img src={Laptop} />
                    <div className='get-started-options'>Train</div>
                    <div className='get-started-options-text'>Use our curriculums as a guide to learn the necessary skills for the job you want.</div>
                </div>
                <div className='get-started__box'
                >
                    <img src={LightSpark} />
                    <div className='get-started-options'>Support</div>
                    <div className='get-started-options-text'>Join inclusive tech communities for resources and support! You are not alone on this journey.</div>
                </div>

            </div>
        </section >

    )
}

export default StartJourney
