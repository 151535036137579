import React, { useEffect } from "react";
import { CFFour, CFOne, CFThree, CFTwo, Spark } from "../../assets";
import { Footer, Header } from "../../components";
import MyLandbot from "../../components/Landbot/Landbot";

import ReactGA from "react-ga";

import "./CareerFinder.scss";

const CareerFinder = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <>
      <div className="careerFinder">
        <div className="careerFinder__details">
          <div className="careerFinder__details-title">
            <img src={Spark} alt="" />
            Career Finder
          </div>
          <div className="careerFinder__details-subtitle">
            Your dream tech career
          </div>
          <ul>
            <li>
              <img src={CFOne} alt="list icon" />
              Get matched to your dream tech career in minutes.
            </li>
            <li>
              <img src={CFTwo} alt="list icon" />
              Find courses to begin training through the curriculum of your
              desired role.
            </li>
            <li>
              <img src={CFThree} alt="list icon" />
              Keep your courses organized with your personalized learning
              dashboard.
            </li>
            <li>
              <img src={CFFour} alt="list icon" />
              Discover new career communities and keep track of existing
              memberships.
            </li>
          </ul>
        </div>
        <div className="landbot__container">
          <MyLandbot url="https://landbot.pro/v3/H-1443293-C1LVOM4PN9G847PW/index.json" />
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default CareerFinder;
