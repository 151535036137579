import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Bolt,
  CRImg,
  DoubleTick,
  RedirectIcon,
  SephoraMLE,
} from "../../assets";

import Storage from "../../services/storageService";
import ReactGA from "react-ga";

import { CourseEnrollCard, JobCard } from "../../components";
import OverviewModal from "../../components/OverviewModal/OverviewModal";
import "./CurriculumOverview.scss";
import getWishlist from "../../services/getWishlist";

const CurriculumOverview = ({ setShowLoginModal, showLoginModal, profile }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();
  const [wishlist, setWishlist] = useState([]);
  const navigate = useNavigate();

  const { id } = useParams();

  const getData = async () => {
    const token = await Storage.getJWTToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_API}/curriculum/${id}/?depth=1`
      // `https://a7cc-39-59-106-33.in.ngrok.io/api/v1/curriculum/${id}/?depth=1`
    );
    console.log(res.data);
    const w = await getWishlist();
    setWishlist(w?.courses);
    setData(res.data.records.fields);
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const response = (array) => {
    const arr = array.split("\n");
    console.log(arr);
    return arr;
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {data && showModal && (
        <OverviewModal
          title={data.career_title}
          response={response(data.responsibilities)}
          setShowModal={setShowModal}
          skills={data.specific_skills}
        />
      )}
      <div className="breadCrumbs">
        <div>
          <Link to="/tech-careers">Browse Tech Careers</Link>
          {">"}
        </div>
        <div>
          <Link>Curriculum</Link>
        </div>
      </div>
      <div className="curriculumOverview">
        {data ? (
          <>
            <div className="curriculumOverview__box">
              <div className="curriculumOverview__box-col-1">
                {data && (
                  <div className="curriculumOverview__box-name">
                    {data?.brands?.brand_name}
                  </div>
                )}{" "}
                {data && (
                  <div className="curriculumOverview__box-course">
                    {data.career_title}
                  </div>
                )}
                {data && <p> {data.description}</p>}
                <div className="curriculumOverview__box-btn-container">
                  <button onClick={() => setShowModal(true)}>
                    Curriculum Overview
                  </button>
                  <a
                    href={data?.reference_file}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <DoubleTick /> Source
                  </a>
                </div>
              </div>
              <div className="curriculumOverview__box-col-2">
                {data && (
                  <JobCard
                    img={data.curriculum_image}
                    place={data.brands ? data.brands.brand_name : ""}
                    job={data.career_title}
                  />
                )}
              </div>
            </div>
            <div className="curriculumCourses">
              <div className="curriculumCourses__head">Curriculum</div>
              <div className="curriculumCourses__title">Courses</div>
              <div className="curriculumCourses__text">
                Learn the skills you actually need.
              </div>
              <div className="curriculumCourses__container">
                {data &&
                  data.selected_courses.map((e) => {
                    if (wishlist) {
                      const w = wishlist.find((w) => w === e.id);
                      return (
                        <CourseEnrollCard
                          img={e.course_image}
                          wl={w}
                          id={e.id}
                          title={e.course_title}
                          link={e.affiliate_link}
                          setShowLoginModal={setShowLoginModal}
                          showLoginModal={showLoginModal}
                          profile={profile}
                        />
                      );
                    }
                    return (
                      <CourseEnrollCard
                        img={e.course_image}
                        id={e.id}
                        title={e.course_title}
                        link={e.affiliate_link}
                        setShowLoginModal={setShowLoginModal}
                        showLoginModal={showLoginModal}
                        profile={profile}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="curriculumAbout">
              <div className="curriculumAbout__col-1">
                {data && (
                  <div className="curriculumAbout__head">
                    {data?.brands?.industry.industry_name}
                  </div>
                )}{" "}
                {data && (
                  <div className="curriculumAbout__title">
                    About {data?.brands?.brand_name}
                  </div>
                )}{" "}
                {data && <p>{data?.brands?.about}</p>}
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={data?.brands?.external_pages}
                >
                  <RedirectIcon /> Explore Careers
                </a>
              </div>
            </div>
            <div className="curriculumBanner">
              <div className="curriculumBanner__text">
                Find the career for you.
              </div>
              <p>Learn through the roles you are interested in pursuing.</p>
              <button onClick={() => navigate("/tech-careers")}>
                Browse <Bolt />
              </button>
            </div>
          </>
        ) : (
          <div className="avatarModal__container">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
            <p>Loading</p>
          </div>
        )}
      </div>
    </>
  );
};

export default CurriculumOverview;
