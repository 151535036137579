import React, { useState } from "react";
import { CreativeDesignNBA } from "../../assets";

import "./AdminCurriculum.scss";

const AdminCurriculum = ({
  name,
  img,
  id,
  request,
  setId,
  setType,
  setModal,
  setEdit,
  data,
}) => {
  const [active, setActive] = useState(false);

  const getAvatar = (url) => {
    if (url.includes("image/upload/http")) {
      const str = url.slice(13);
      return str;
    } else {
      return url;
    }
  };

  const handleDelete = async () => {
    setId(id);
    setType("curriculum");
    setModal(true);
  };

  return (
    <div className="adminCurriculum">
      <menu onClick={() => setActive(!active)}>
        <span></span>
        <span></span>
        <span></span>
        {active && (
          <ul>
            <li
              onClick={() =>
                setEdit("Curriculum", {
                  title: data.career_title,
                  image: data?.curriculum_image,
                  link: data.learn_more_link,
                  buyLink: data.reference_file,
                  category: data.category,
                  time: data.total_completion_time,
                  descrip: data.description,
                  id: data.id,
                })
              }
            >
              Edit
            </li>
            <li onClick={handleDelete}>Delete</li>
          </ul>
        )}
      </menu>
      {img && img.includes("res.") ? (
        <img src={getAvatar(img)} alt="admin curriculum card img" />
      ) : (
        <img
          src={
            "https://res.cloudinary.com/dsfnoazam/image/upload/v1671417999/yekshdmzy8kohpxklikh.png"
          }
          alt="admin curriculum card img"
        />
      )}
      <div className="adminCurriculum__name">{name}</div>
    </div>
  );
};

export default AdminCurriculum;
