import React, { useState } from "react";

import {
  Add,
  Category,
  CloseIcon,
  CourseBuyLink,
  CourseLink,
  CourseName,
  Descrip,
  EstimatedTime,
} from "../../assets";
import axios from "axios";

const AddNewCurriculum = ({ setShowModal }) => {
  const [courseName, setCourseName] = useState("");
  const [courseLink, setCourseLink] = useState("");
  const [courseBuyLink, setCourseBuyLink] = useState("");
  const [category, setCategory] = useState("");
  const [estimatedTime, setEstimatedTime] = useState("");
  const [descrip, setDescrip] = useState("");
  const [img, setImg] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (img) {
        const formData = new FormData();
        formData.append("file", img);
        formData.append("upload_preset", "yi1vonhk");

        const upload = await axios.post(
          "https://api.cloudinary.com/v1_1/dsfnoazam/image/upload",
          formData
        );

        const res = await axios.post(
          `${process.env.REACT_APP_API}/curriculum/`,
          {
            career_title: courseName,
            description: descrip,
            curriculum_image: upload.data.secure_url,

            affiliate_link: courseBuyLink,
            total_completion_time: estimatedTime,
            learn_more_link: courseLink,
            QA: true,
            reference: courseLink,
            reference_file: "string",
            responsibilities: "string",
            short_description: "string",
            total_estimated_cost: "20",
            // category: "Fashion/Retail",
          }
        );
        console.log(res);
        setShowModal(false);
      }
      else{
        alert("Kindly upload an image.")
      }
    } catch (err) {
      console.log("Add");
    }
  };

  return (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          setShowModal(false);
        }
      }}
      className="deleteModal__container"
    >
      <div className="addNewCourse">
        <CloseIcon className="close" onClick={() => setShowModal(false)} />
        <div className="addNewCourse__title"> Add New Curriculum</div>
        <div className="addNewCourse__container">
          <section>
            <menu>
              {img && <img src={img} alt="" />}
              <label htmlFor="avatar">
                <Add />
              </label>
              <input
                type="file"
                id="avatar"
                name="avatar"
                required
                accept="image/*"
                onChange={(e) => {
                  // setImg(e.target.files[0]);
                  let reader = new FileReader();
                  reader.readAsDataURL(e.target.files[0]);
                  reader.onload = () => {
                    console.log(reader);
                    setImg(reader.result);
                  };
                }}
              />
            </menu>
            <form onSubmit={handleSubmit}>
              <label>
                <CourseName />
                <input
                  placeholder="Curriculum Name"
                  type="text"
                  required
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                  />
              </label>
              <label>
                <CourseLink />
                <input
                  placeholder="Curriculum Link"
                  required
                  type="text"
                  value={courseLink}
                  onChange={(e) => setCourseLink(e.target.value)}
                  />
              </label>
              <label>
                <CourseBuyLink />
                <input
                  placeholder="Curriculum Buy Link"
                  required
                  type="text"
                  value={courseBuyLink}
                  onChange={(e) => setCourseBuyLink(e.target.value)}
                  />
              </label>
              <label>
                <Category />
                <input
                  placeholder="Category"
                  required
                  type="text"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  />
              </label>
              <label>
                <EstimatedTime />
                <input
                  placeholder="Estimated Time"
                  type="text"
                  value={estimatedTime}
                  required
                  onChange={(e) => setEstimatedTime(e.target.value)}
                  />
              </label>
              <div>
                <Descrip />
                <textarea
                  placeholder="Add Description"
                  draggable={false}
                  required
                  value={descrip}
                  onChange={(e) => setDescrip(e.target.value)}
                ></textarea>
              </div>
              <button type="submit">Save</button>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddNewCurriculum;
