import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  redirect,
  Navigate,
} from "react-router-dom";
import {
  Admin,
  CurriculumOverview,
  Home,
  MeetTheTeam,
  Profile,
  TechCareers,
} from "./pages";
import { Header, Footer } from "./components";
import CourseDetails from "./pages/CourseDetail/CourseDetails";
import CareerFinder from "./pages/CareerFinder/CareerFinder";
import { useEffect, useState } from "react";
import axios from "axios";
import Storage from "./services/storageService";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import EditProfile from "./pages/EditProfile/EditProfile";
import ResetEmail from "./pages/ResetEmail/ResetEmail";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-229688087-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// React

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const localToken = localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);

  const getData = async () => {
    const token = await Storage.getJWTToken();
    console.log(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.get(`${process.env.REACT_APP_API}/me`, config);
    setData(res.data);
  };
  useEffect(() => {
    if (localStorage.getItem("token")) getData();
  }, [localToken]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    navigate("/");
    setData("");
    window.location.reload(false);
  };

  return (
    <div
      className={`App ${
        location.pathname === "/career-finder" ? "career-finder" : ""
      }`}
      style={{
        background:
          location.pathname === "/tech-careers"
            ? "linear-gradient(193.58deg, #FBF2EF 0.84%, #F4F5FD 29.52%, #F4F5FD 68.44%, #FBF2EF 99.17%)"
            : false ||
              location.pathname === "/profile" ||
              (location.path === "/career-finder" && "#F8F8F8"),
      }}
    >
      {location.pathname !== "/profile" &&
        location.pathname !== "/resetPassword" &&
        location.pathname !== "/resetEmail" &&
        location.pathname !== "/editProfile" &&
        location.pathname !== "/confirmEmail" &&
        location.pathname !== "/admin" && (
          <Header
            callProfile={getData}
            profile={data ? data : ""}
            setShowModal={setShowModal}
            showModal={showModal}
            handleLogout={handleLogout}
            background={location.pathname === "/" ? false : true}
          />
        )}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/tech-careers"
          element={
            <TechCareers setShowModal={setShowModal} showModal={showModal} />
          }
        />
        {/* <Route exact path="/meet-the-team" element={<MeetTheTeam />} /> */}
        <Route
          exact
          path="/profile"
          element={
            localStorage.getItem("token") ? (
              <Profile handleLogout={handleLogout} profile={data ? data : ""} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          exact
          path="/curriculum-overview/:id"
          element={
            <CurriculumOverview
              setShowLoginModal={setShowModal}
              showLoginModal={showModal}
              profile={data}
            />
          }
        />
        <Route exact path="/linkedin" element={<LinkedInCallback />} />
        <Route exact path="/career-finder" element={<CareerFinder />} />
        <Route exact path="/resetPassword" element={<ResetPassword />} />
        <Route
          exact
          path="/editProfile"
          element={
            localStorage.getItem("token") ? (
              <EditProfile />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route exact path="/resetEmail" element={<ResetEmail />} />
        <Route exact path="/confirmEmail" element={<ConfirmEmail />} />
        <Route
          exact
          path={`/course-details/:id`}
          element={
            <CourseDetails
              setShowLoginModal={setShowModal}
              showLoginModal={showModal}
              profile={data}
            />
          }
        />
        {data && data?.is_superuser && (
          <Route exact path="/admin" element={<Admin />} />
        )}
      </Routes>
      {location.pathname !== "/profile" &&
        location.pathname !== "/career-finder" &&
        location.pathname !== "/resetPassword" &&
        location.pathname !== "/resetEmail" &&
        location.pathname !== "/confirmEmail" &&
        location.pathname !== "/editProfile" &&
        location.pathname !== "/admin" && <Footer />}
    </div>
  );
};

export default App;
