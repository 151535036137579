import React, { useEffect, useState } from "react";

import "./ConfirmEmail.scss";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import ReactGA from "react-ga";

const ConfirmEmail = () => {
  const [message, setMessage] = useState(null);
  const [query, setQuery] = useSearchParams();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const res = axios.post(
          "https://secure-answer-368913.uc.r.appspot.com/api/v1/validate-updated-email",
          {
            token: query.get("token"),
          }
        );
        setMessage("You email got successfully reset!");
      } catch (err) {
        setMessage("Your token got expired!");
      }
    };
    verifyEmail();
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return message && <h1 className="confirmEmail__message">{message}</h1>;
};

export default ConfirmEmail;
