import React, { useState } from "react";

import "./LearnList.scss";
import axios from "axios";

import Storage from "../../services/storageService";

const LearnList = ({
  id,
  setDeleteId,
  setShowModal,
  img,
  title,
  request,
  brand,
  link,
}) => {
  const [menu, setMenu] = useState(false);

  const handleClick = () => {
    setDeleteId(id);
    // setShowModal(true);
  };

  const handleDelete = async () => {
    const token = await Storage.getJWTToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios.delete(
      `${process.env.REACT_APP_API}/learn_list_write/${id}`,
      config
    );
    request();
  };

  return (
    <>
      <div className="learnList">
        <menu onClick={(e) => e.currentTarget === e.target && setMenu(!menu)}>
          <div></div>
          <div></div>
          <div></div>
          {menu && <section onClick={handleDelete}>Delete</section>}
        </menu>
        <div
          onClick={() => window.open(link, "_blank")}
          className="learnList__name"
        >
          <span>{title}</span>
          {img ? <img src={img} alt="Logo" /> : <div>{brand}</div>}
        </div>
        <div
          onClick={() => window.open(link, "_blank")}
          className="learnList__course"
        >
          <div className="learnList__course-title">{title}</div>
          <div className="learnList__button-box">
            {/* <button>I've Entrolled</button>
            <button>Learn More</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default LearnList;
