import React, { useEffect, useState } from "react";

import Carousel from "react-multi-carousel";

import { Jumbotron, JobCard } from "../../components";

import CountUp from "react-countup";

import ReactGA from "react-ga";

import {
  RightArrow,
  Hands,
  AI,
  MarketPlaceImg,
  Sephora,
  CashApp,
  Nike,
  Spotify,
  Community,
  Spark,
  BlackTeachPipelineLogo,
  NGCPLogo,
  TeachForAmericaLogo,
  TheReinventionLabLogo,
  Sparky,
  Rocket,
  CFOne,
  CFTwo,
  CFThree,
  CFFour,
  ThunderBolt,
  Tech1,
  Tech2,
  Tech3,
  Tech4,
  Tech5,
  Search,
  CurriculumFilters,
  PartnerShips,
} from "../../assets/index.js";

import "./Home.scss";
import { Link, useNavigate } from "react-router-dom";
import MyLandbot from "../../components/Landbot/Landbot";

const Home = () => {
  const jobs = [
    {
      img: CashApp,
      job: "Product Designer",
      place: "Cash App",
    },
    {
      img: Spotify,
      job: "Product Marketing",
      place: "Shopify",
    },
    {
      img: Sephora,
      job: "Data Scientist",
      place: "Sephora",
    },
    {
      img: Nike,
      job: "Software Engineer",
      place: "Nike",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1230, min: 730 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 730, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  

  return (
    <>
      <Jumbotron />
      <div className="home__career-finder">
        <div className="home__career-finder-details">
          <h2>
            How to get started <img src={Rocket} alt="rocket" />
          </h2>
          <ul>
            <li>
              <img src={CFOne} alt="list icon" />
              Get matched to your dream tech career in minutes.
            </li>
            <li>
              <img src={CFTwo} alt="list icon" />
              Find courses to begin training through the curriculum of your
              desired role.
            </li>
            <li>
              <img src={CFThree} alt="list icon" />
              Keep your courses organized with your personalized learning
              dashboard.
            </li>
            <li>
              <img src={CFFour} alt="list icon" />
              Discover new career communities and keep track of existing
              memberships.
            </li>
          </ul>
        </div>
        <MyLandbot url="https://landbot.pro/v3/H-1443293-C1LVOM4PN9G847PW/index.json" />
      </div>
      <div className="home__technology">
        <Tech1 className="svg" />
        <Tech2 className="svg" />
        <Tech3 className="svg" />
        <Tech4 className="svg" />
        <Tech5 className="svg" />
        {/* <img src={Tech1} alt="" /> */}
        {/* <img src={Tech2} alt="" /> */}
        {/* <img src={Tech3} alt="" /> */}
        {/* <img src={Tech4} alt="" /> */}
        {/* <img src={Tech5} alt="" /> */}
        <div className="home__technology-container">
          <div className="home__technology-title">
            <ThunderBolt /> TECHNOLOGY POWERS EVERY INDUSTRY <ThunderBolt />
          </div>
          <div
            className="home__technology-button"
            onClick={() => navigate("/tech-careers")}
          >
            Explore Careers <span>➔</span>
          </div>
        </div>
      </div>
      <div className="home__courses">
        <div className="home__courses-container">
          <div className="home__courses-title">
            Find courses to take, directly through the brands you love.
          </div>
          <p>
            We partnered with Linkedin Learning, Coursera, and Skillshare to
            help you navigate the specific set of skills each role requires.
          </p>
          <PartnerShips />
          <button onClick={() => navigate("/tech-careers")}>
            Browse Careers <Search />
          </button>
        </div>
        <div className="home__courses-img"></div>
      </div>
      <div className="home__find-career">
        <div className="home__find-career-col-1">
          <div className="home__find-career-title">
            FIND A CAREER THAT ALIGNS WITH YOUR INTERESTS.
          </div>
          <div className="home__find-career-para">
            Our career matching tool takes a few minutes to complete and gives
            you instant clarity. ⚡️
          </div>
          <div className="home__find-career-count">
            <CountUp duration={72} end={3244} />
          </div>
          <div className="home__find-career-text">matches and counting</div>
          <div
            className="home__find-career-btn"
            onClick={() => navigate("/career-finder")}
          >
            Get Matched <img src={Hands} alt="Shake hands" />
          </div>
        </div>
        <div className="home__find-career-col-2">
          <img src={AI} alt="AI" />
        </div>
      </div>
      <div className="home__companies">
        <Carousel
          arrows={false}
          responsive={responsive}
          renderButtonGroupOutside={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
        >
          <img src={NGCPLogo} alt="NGCP" />
          <img src={TeachForAmericaLogo} alt="Teach for America" />
          <img src={BlackTeachPipelineLogo} alt="Black Teach Pipeline" />
          <img src={TheReinventionLabLogo} alt="The Reinvention Lab" />
        </Carousel>
      </div>
      {/*
        <div className="home__marketplace">
          <img src={MarketPlaceImg} alt="Marketplace img" />
          <div className="home__marketplace-container">
            <div className="home__marketplace-head">MARKETPLACE</div>
            <div className="home__marketplace-title">
              Your Tech Career Blueprint
            </div>
            <p className="home__marketplace-para">
              Focus on developing the tech skills required for your career instead
              of wasting time figuring out what you need to know.
            </p>
            <Link to="/tech-careers">
              Browse Curriculums <RightArrow />
            </Link>
          </div>
        </div>
        <div className="home__jobs">
          {jobs.map((job, ind) => (
            <JobCard img={job.img} job={job.job} place={job.place} key={ind} />
          ))}
        </div>
        <div className="home__community">
          <img src={Community} alt="community" />
          <div className="home__community-box">
            <img src={Spark} alt="spark" className="home__community-spark" />
            <div className="home__community-head">COMMUNITY</div>
            <div className="home__community-title">
              Hold yourself accountable.
            </div>
            <p>
              Serious about getting closer to your career in tech? Surround
              yourself with brilliant minds & learn from others.
            </p>
            <button>
              Join a community <Sparky />{" "}
            </button>
          </div>
        </div>
        <div className="home__companies">
          <Carousel
            arrows={false}
            responsive={responsive}
            renderButtonGroupOutside={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
          >
            <img src={NGCPLogo} alt="NGCP" />
            <img src={TeachForAmericaLogo} alt="Teach for America" />
            <img src={BlackTeachPipelineLogo} alt="Black Teach Pipeline" />
            <img src={TheReinventionLabLogo} alt="The Reinvention Lab" />
          </Carousel>
        </div>
      {/* 
      {/*
       */}
    </>
  );
};

export default Home;
