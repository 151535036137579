import React from "react";
import { ReactComponent as BACK } from "../../assets/icons/back.svg";

import "./ButtonGroup.scss"

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="button__group">
      <main onClick={() => previous()}>
        <BACK />
      </main>
      <main onClick={() => next()}>
        <BACK />
      </main>

      {/* <ButtonOne
        className={currentSlide === 0 ? "disable" : ""}
        onClick={() => previous()}
      />
      <ButtonTwo onClick={() => next()} /> */}
    </div>
  );
};

export default ButtonGroup;
