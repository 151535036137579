import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { CloseIcon } from "../../assets";
import Storage from "../../services/storageService";

import "./AvatarModal.scss";

const AvatarModal = ({ setShowModal, setData }) => {
  const subdomain = "infinity-careers"; // See section about becoming a partner
  const iFrameRef = useRef(null);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [showIFrame, setShowIFrame] = useState(true);

  useEffect(() => {
    let iFrame = iFrameRef.current;
    if (iFrame) {
      iFrame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
    }
  });
  useEffect(() => {
    window.addEventListener("message", subscribe);
    document.addEventListener("message", subscribe);
    return () => {
      window.removeEventListener("message", subscribe);
      document.removeEventListener("message", subscribe);
    };
  });

  function subscribe(event) {
    const json = parse(event);
    if (json?.source !== "readyplayerme") {
      return;
    }
    // Subscribe to all events sent from Ready Player Me
    // once frame is ready
    if (json.eventName === "v1.frame.ready") {
      let iFrame = iFrameRef.current;
      if (iFrame && iFrame.contentWindow) {
        iFrame.contentWindow.postMessage(
          JSON.stringify({
            target: "readyplayerme",
            type: "subscribe",
            eventName: "v1.**",
          }),
          "*"
        );
      }
    }
    // Get avatar GLB URL
    if (json.eventName === "v1.avatar.exported") {
      let url = json.data.url.slice(0, -3);
      changeAvatar(`${url}png`);
      console.log(`${url}png`);

      setAvatarUrl(json.data.url);
      setShowIFrame(false);
    }
    // Get user id
    if (json.eventName === "v1.user.set") {
      console.log(`User with id ${json.data.id} set:
${JSON.stringify(json)}`);
    }
  }
  function parse(event) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }

  const changeAvatar = async (url) => {
    const token = await Storage.getJWTToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_API}/edit-avatar`,
      {
        avatar: url,
      },
      config
    );
    await setData(res.data);
    setShowModal(false);
  };

  return (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          setShowModal(false);
        }
      }}
      className="communityModal__container"
    >
      <div className="avatarModal">
        <CloseIcon className="close" onClick={() => setShowModal(false)} />
        <iframe
          allow="camera *; microphone *"
          className="iFrame"
          id="frame"
          ref={iFrameRef}
          style={{
            display: `${showIFrame ? "block" : "none"}`,
          }}
          title={"Ready Player Me"}
        />
        {!showIFrame && (
          <div className="avatarModal__container">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
            <p>Updating avatar...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvatarModal;
