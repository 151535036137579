import React from 'react'
import './CareerButton.scss'
import { useNavigate } from 'react-router-dom'

const CareerButton = ({ children }) => {
    const navigate = useNavigate()
    return (
        <button onClick={()=> navigate("/career-finder")}  className='career_button'>
            {children}
        </button>
    )
}

export default CareerButton