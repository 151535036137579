import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ScrollToTop from "./services/scrollToTop";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import "./index.scss";
import "react-multi-carousel/lib/styles.css";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <ScrollToTop />
    <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }}>
      <App />
    </SnackbarProvider>
  </Router>
);
