import React, { useEffect } from "react";
import { CloseIcon } from "../../assets";
import Storage from "../../services/storageService";
import "./DeleteModal.scss";
import axios from "axios";
import { Navigate, useLocation } from "react-router-dom";

const DeleteModal = ({ setShowModal, deleteId, type, request }) => {
  const location = useLocation();
  const handleClick = async () => {
    const res = await axios.delete(
      `${process.env.REACT_APP_API}/${type}/${deleteId}`
    );
    request();
    setShowModal(false);
  };

  const handleDelete = async () => {
    try {
      const token = await Storage.getJWTToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.delete(
        `${process.env.REACT_APP_API}/auth/delete-account/?uuid=${deleteId}`,
        // `https://a7cc-39-59-106-33.in.ngrok.io/api/v1/auth/delete-account/?uuid=${deleteId}`,
        config
      );
      console.log(res);
      localStorage.removeItem("token");
      location.href = `${window.location.origin}/`;
      window.location.reload(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          setShowModal(false);
        }
      }}
      className="deleteModal__container"
    >
      <div className="deleteModal">
        <CloseIcon className="close" onClick={() => setShowModal(false)} />
        {type ? (
          <div className="communityModal__title">Remove Course</div>
        ) : (
          <div className="communityModal__title">Delete Account</div>
        )}
        <main>
          <section>
            {type ? (
              <div className="deleteModal__title">
                Want to remove this course?
              </div>
            ) : (
              <div className="deleteModal__title">
                Want to delete your account?
              </div>
            )}
            <div className="deleteModal__btn-container">
              {type ? (
                <button onClick={() => handleClick()}>Remove</button>
              ) : (
                <button onClick={() => handleDelete()}>Delete</button>
              )}
              <button onClick={() => setShowModal(false)}>No</button>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default DeleteModal;
