import React, { useState } from "react";
import {
  Bolt,
  GSV,
  Hand,
  InfinityFooterLogo,
  InstagramIcon,
  LinkedInIcon,
  PinterestIcon,
  Rocket,
  Search,
  Star,
  InfinityBlackSmallLogo,
  TwitterIcon,
} from "../../assets";

import "./Footer.scss";

const Footer = ({style}) => {
  const [email, setEmail] = useState("");
  return (
    <footer style={style ? style : {}} className="footer">
      <div className="footer-1">
        <div className="footer__gsv">
          <img src={GSV} alt="GSV" />
        </div>
        <div className="footer__socials">
          <div className="footer__socials-box">
            <TwitterIcon />
            <LinkedInIcon />
            <InstagramIcon />
          </div>
          <a
            href="https://www.producthunt.com/posts/infinitycareers?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-infinitycareers"
            target="_blank"
            rel="noreferrer"
            style={{ marginTop: "5rem" }}
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=353836&theme=light"
              alt="InfinityCareers - Learn&#0032;tech&#0032;skills&#0032;through&#0032;the&#0032;brands&#0032;you&#0032;love | Product Hunt"
              width="250"
              height="54"
            />
          </a>
          <p>
            To infinity and beyond <img src={Rocket} alt="Rocket emoji" />
          </p>
        </div>
        <div className="footer__company">
          <img src={InfinityBlackSmallLogo} alt="infinity footer logo" />

          <p>
            infinity.careers is a tech career exploration and talent training
            marketplace.
          </p>
          <form>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={"Enter your email"}
            />
            <button>Subscribe</button>
          </form>
        </div>
      </div>
      <div className="footer-2">
        <div>Copyright ©2022 ∞edu. All rights reserved.</div>
        <ul>
          <li>
            <a href="/career-finder">
              Career Finder <Bolt />
            </a>
          </li>
          <li>
            <a href="/tech-careers">
              Browse Tech Careers <Search />
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target={"_blank"}
              href="https://infinitycareers.notion.site/Team-7c8b89f2ccbc48f5b212528c7b8f6cb3"
            >
              Meet the team <Hand />
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target={"_blank"}
              href="https://airtable.com/shrs8XCiH2zhHD107"
            >
              Partner with us  &nbsp;💫
            </a>
          </li>
          <li>
            <a
             rel="noreferrer"
             target={"_blank"}
            href="https://airtable.com/shrzSSdxFxAXY6fkI">Talk to us!</a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target={"_blank"}
              href="https://infinitycareers.notion.site/Affiliate-Disclosure-4d3ab7fc8fb14afab83977b35e4d94f2"
            >
              Affiliate Disclosure
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
