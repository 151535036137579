import React, { useEffect, useState } from "react";

import "./ResetPassword.scss";
import { Hidden } from "../../assets";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import ReactGA from "react-ga";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hidden, setHidden] = useState([false, false]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Make sure that both passwords match!");
    } else {
      try {
        const res = await axios.post(
          "https://secure-answer-368913.uc.r.appspot.com/api/v1/auth/password-resetconfirm/",
          {
            password: password,
            token: query.get("token"),
          }
        );
        console.log(res);
        if (res.status === 200) {
          navigate("/", { replace: true });
        }
      } catch (err) {
        console.log(err);
        setError(err.response.data.password[0]);
      }
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="resetPassword">
      <div>Reset your password</div>
      <form onSubmit={handleSubmit}>
        <label>
          <span>New password</span>
          <section>
            <input
              type={hidden[0] ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter new password"
            />
            <img
              src={Hidden}
              onClick={() => setHidden([!hidden[0], hidden[1]])}
              alt=""
            />
          </section>
        </label>
        <label>
          <span>Confirm password</span>
          <section>
            <input
              type={hidden[1] ? "text" : "password"}
              value={confirmPassword}
              placeholder="Enter confirm password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <img
              src={Hidden}
              onClick={() => setHidden([hidden[0], !hidden[1]])}
              alt=""
            />
          </section>
        </label>
        {error && <p>{error}</p>}
        <button type="submit">Reset my password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
