import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";
// import "./ResetEmail.scss";
const ResetEmail = () => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [error, setError] = useState("");
  const [query, setQuery] = useSearchParams();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email !== confirmEmail)
      return setError("Please make sure both emails match!");
    try {
      const res = axios.post(
        "https://secure-answer-368913.uc.r.appspot.com/api/v1/validate-existing-email",
        {
          new_email: email,
          token: query.get("token"),
        }
      );
      console.log(res);
      setError("Email verification sent to " + email);
      localStorage.removeItem("token");
      localStorage.clear();

      //   if (res.status === 200) navigate("/", { replace: "true" });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="resetPassword">
      <div>Reset your email</div>
      <form onSubmit={handleSubmit}>
        <label>
          <span>New Email</span>
          <section>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter new email"
            />
          </section>
        </label>
        <label>
          <span>Confirm Email</span>
          <section>
            <input
              type="email"
              value={confirmEmail}
              placeholder="Enter confirm email"
              onChange={(e) => setConfirmEmail(e.target.value)}
            />
          </section>
        </label>
        {error && (
          <p
            className={`${
              error.includes("Email verification sent to") && "active"
            }`}
          >
            {error}
          </p>
        )}
        <button type="submit">Reset my email</button>
      </form>
    </div>
  );
};
export default ResetEmail;
