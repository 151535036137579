import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Storage from "../../services/storageService";

import "./Header.scss";

import {
  Bolt,
  Search,
  Profile,
  InfinityBlackLogo,
  InfinityBlackSmallLogo,
  Star,
  Hand,
} from "../../assets";
import { AuthModal } from "../index";

const Header = ({
  background,
  profile,
  callProfile,
  showModal,
  setShowModal,
  handleLogout,
}) => {
  const navigate = useNavigate();

  const [small, setSmall] = useState(false);
  const [active, setActive] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [data, setData] = useState(profile);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        setSmall(window.pageYOffset > 800);
      });
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    }
  }, []);

  useEffect(() => {
    setData(profile);
  }, [profile]);

  const localToken = localStorage.getItem("token");

  const getAvatar = (url) => {
    if (url.includes("image/upload/http")) {
      const str = url.slice(13);
      return str;
    } else {
      return url;
    }
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     const token = await Storage.getJWTToken();
  //     console.log(token);
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };
  //     const res = await axios.get(`${process.env.REACT_APP_API}/me`, config);
  //     setData(res.data);
  //   };
  //   if (localStorage.getItem("token")) getData();
  // }, [localToken]);

  const url = window.location.href;

  useEffect(() => {
    setActive(false);
  }, [url]);

  return (
    <>
      {showModal && (
        <AuthModal callProfile={callProfile} setShowModal={setShowModal} />
      )}
      <div
        className={`header ${active ? "active" : ""}  ${
          !background && "transparent"
        } ${!background && small ? "small" : ""}`}
      >
        {width <= 900 ? (
          <>
            <div className="header__logo-container">
              <div
                onClick={() => setActive(!active)}
                className={`header__hamburger ${active ? "active" : ""}`}
              >
                <div></div>
                <div></div>
              </div>
              <img
                src={InfinityBlackLogo}
                alt="infinity-header-logo"
                className="header__logo"
                onClick={() => navigate("/")}
                style={{
                  cursor: "pointer",
                  width: "24rem",
                  marginBlock: "0.5rem",
                }}
              />
              {data &&
                (data.avatar && data?.avatar !== "image/upload/string" ? (
                  <div
                    onClick={() => navigate("/profile")}
                    className="profile__avatar"
                  >
                    <img src={getAvatar(data.avatar)} alt="profile pic" />
                    {/* <span>
                          Edit <br /> Avatar
                        </span> */}
                  </div>
                ) : (
                  <div
                    onClick={() => navigate("/profile")}
                    className="profile__avatar"
                  >
                    <div className="profile__avatar-container">
                      {data?.first_name
                        ? data?.first_name[0].toUpperCase()
                        : ""}
                      {data?.last_name ? data?.last_name[0].toUpperCase() : ""}
                    </div>
                    {/* <span>
                          Edit <br /> Avatar
                        </span> */}
                  </div>
                ))}
            </div>
            <div className="header__menu">
              <Link to={"/career-finder"}>
                Career Finder <Bolt />
              </Link>
              <Link to={"/tech-careers"}>
                Browse Tech Careers <Search />{" "}
              </Link>
              {/* <Link to={"/meet-the-team"}>
                Meet the team <Hand />{" "}
              </Link> */}
              <a
                rel="noreferrer"
                href="https://infinitycareers.notion.site/Team-7c8b89f2ccbc48f5b212528c7b8f6cb3"
                target={"_blank"}
              >
                Meet The Team <Hand />
              </a>
              {localStorage.getItem("token") && data && (
                <Link to="/profile">Profile</Link>
              )}
              {localStorage.getItem("token") && (
                <button
                  onClick={() => {
                    setActive(false);
                    handleLogout();
                  }}
                >
                  Logout
                </button>
              )}
              {!localStorage.getItem("token") && (
                <button
                  className="login"
                  onClick={() => {
                    setActive(false);
                    setShowModal(true);
                  }}
                >
                  Login
                </button>
              )}
            </div>
          </>
        ) : (
          <img
            src={width > 1400 ? InfinityBlackLogo : InfinityBlackSmallLogo}
            alt="infinity-header-logo"
            className="header__logo"
            onClick={() => navigate("/")}
          />
        )}
        <ul>
          <li>
            <Link to="/career-finder">
              Career Finder <Bolt />
            </Link>
          </li>
          <li>
            <Link to="/tech-careers">
              Browse Tech Careers <Search />
            </Link>
          </li>
          {/* <li>
            <Link to="/meet-the-team">
              Meet the team <Hand />
            </Link>
          </li> */}
          <li>
            <a
              rel="noreferrer"
              href="https://infinitycareers.notion.site/Team-7c8b89f2ccbc48f5b212528c7b8f6cb3"
              target={"_blank"}
            >
              Meet The Team <Hand />
            </a>
          </li>
          {localStorage.getItem("token") && data && (
            <li>
              <Link to="/profile">
                <Profile /> {data.first_name}
              </Link>
            </li>
          )}
        </ul>
        {!localStorage.getItem("token") && (
          <button
            className="header__login-btn"
            onClick={() => setShowModal(true)}
          >
            Login
          </button>
        )}
      </div>
    </>
  );
};

export default Header;
