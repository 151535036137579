import { useStepContext } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Books,
  Brand,
  Heart,
  Home,
  Industry,
  Pencil,
  Prefences,
  Sparky,
  InfinityWhiteLogo,
} from "../../assets";
import {
  CommunityModal,
  CourseEnrollCard,
  DeleteModal,
  Footer,
  Header,
  LearnList,
  NewCourseModal,
  TechCareersFilterCard,
} from "../../components";
import AvatarModal from "../../components/AvatarModal/AvatarModal";
import EditPreferences from "../../components/EditPreferences/EditPreferences";
import Storage from "../../services/storageService";

import "./Profile.scss";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";

import ReactGA from "react-ga";

const Profile = ({ profile, handleLogout }) => {
  const [wishlist, setWishlist] = useState("curriculum");
  const [deleteId, setDeleteId] = useState(null);
  const [data, setData] = useState(profile);
  const [wishlistData, setWishlistData] = useState(null);
  const [learnlist, setLearnList] = useState(null);
  const [completionRate, setCompletionRate] = useState(0);
  const [query] = useSearchParams();

  useEffect(() => {
    setData(profile);
  }, [profile]);

  const responsive = {
    bigDesktop: {
      breakpoint: { max: 3000, min: 2000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1400 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1400, min: 900 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 900, min: 675 },
      items: 2,
      slidesToSlide: 1,
    },
    smMobile: {
      breakpoint: { max: 675, min: 250 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const wishlistResponsive = {
    bigDesktop: {
      breakpoint: { max: 3000, min: 2000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1400 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1400, min: 900 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 900, min: 675 },
      items: 2,
      slidesToSlide: 1,
    },
    smMobile: {
      breakpoint: { max: 675, min: 250 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const [showCommunityModal, setShowCommunityModal] = useState(false);
  const [showCourseModal, setShowCourseModal] = useState(false);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [preferenceModal, setPreferenceModal] = useState(false);
  const [preferences, setPreferences] = useState(null);
  const [community, setCommunity] = useState("");
  const [hamburger, setHamburger] = useState(false);
  const [defaultCommunities, setDefaultCommunities] = useState(null);
  const [recommandedCurriculums, setRecommandedCurriculums] = useState(null);

  const getData = async () => {
    const token = await Storage.getJWTToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // const res = await axios.get(`${process.env.REACT_APP_API}/me`, config);
    // setData(res.data);
    const wishData = await axios.get(
      `${process.env.REACT_APP_API}/wishlist-read`,
      config
    );
    const curriculums = await axios.get(
      `${process.env.REACT_APP_API}/curriculum`,
      config
    );
    const courses = await axios.get(
      `${process.env.REACT_APP_API}/courses`,
      config
    );
    const com = await axios.get(
      `${process.env.REACT_APP_API}/user-communities`,
      config
    );
    const { data: defaultCommunitiesArray } = await axios.get(
      `${process.env.REACT_APP_API}/communities`,
      config
    );

    let defaultCommunity = [];
    defaultCommunitiesArray.map(
      (e) => e.provided_by_platform && defaultCommunity.push(e)
    );
    setDefaultCommunities([...defaultCommunity]);
    console.log(wishData);
    console.log(curriculums);
    console.log(courses);
    setCommunity(com.data);
    const course = courses.data.filter((e) =>
      // console.log(e)
      wishData.data[0].courses.find((j) => e.id === j)
    );
    const curriculum = curriculums.data.filter((e) =>
      // console.log(e)
      wishData.data[0].curriculums.find((j) => e.id === j)
    );

    setWishlistData({
      curriculums: curriculum,
      courses: course,
    });
  };

  useEffect(() => {
    getData();
    getLearnList();
  }, []);

  useEffect(() => {
    if (data) {
      let arr = {
        industry: [],
        brand: [],
        role: ["Copywriter", "Community Lead"],
        location: data.locations,
        skill: [],
      };
      data.favorite_industries.map((e) => arr.industry.push(e.industry_name));
      data.favorite_brands.map((e) => arr.brand.push(e.brand_name));
      data.skills.map((e) => arr.skill.push(e.skill));
      setPreferences({ ...arr });
    }
  }, [data]);

  const getLearnList = async () => {
    setLearnList(null);
    const token = await Storage.getJWTToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const ll = await axios.get(
      `${process.env.REACT_APP_API}/learn_list_read`,
      config
    );

    let list = [];

    // ll.data.map(async (e) => {
    //   const img = await axios.get(
    //     `${process.env.REACT_APP_API}/platform-logo/${e.platform_logo}`,
    //     config
    //   );
    //   list.push({ ...e, img: img.data.logo.slice(13) });
    // });
    // console.log(list)

    // setLearnList(list);
    setLearnList(null);
    setLearnList(ll.data);

    console.log(ll.data.data);
  };

  const navigate = useNavigate();

  const getAvatar = (url) => {
    if (url.includes("image/upload/http")) {
      const str = url.slice(13);
      return str;
    } else {
      return url;
    }
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    let completed = 0;

    if (!data?.avatar) {
      completed += 1;
    }
    if (!data?.favorite_brands?.length > 0) {
      completed += 1;
    }
    if (!data?.skills?.length > 0) {
      completed += 1;
    }
    if (!data?.favorite_industries?.length > 0) {
      completed += 1;
    }
    // if (!data?.statements?.length > 0) {
    //   completed += 1;
    // }

    setCompletionRate(100 - (completed / 4) * 100);
  }, [data]);

  const getCommunites = async () => {
    const token = await Storage.getJWTToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const com = await axios.get(
      `${process.env.REACT_APP_API}/user-communities`,
      config
    );
    setCommunity(com.data);
  };

  const deleteCommunity = async (id) => {
    const token = await Storage.getJWTToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const com = await axios.delete(
      `${process.env.REACT_APP_API}/delete_community/?id=${id}`,
      config
    );

    getCommunites();
  };

  useEffect(() => {
    if (data) {
      if (query.get("scroll") === "communities") {
        handleClickScroll("communities");
      }
    }
  }, [data]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {showCommunityModal && (
        <CommunityModal
          request={getCommunites}
          setShowModal={setShowCommunityModal}
        />
      )}
      {showCourseModal && (
        <NewCourseModal
          request={getLearnList}
          setShowModal={setShowCourseModal}
        />
      )}{" "}
      {showAvatarModal && (
        <AvatarModal setData={setData} setShowModal={setShowAvatarModal} />
      )}{" "}
      {deleteModal && (
        <DeleteModal deleteId={deleteId} setShowModal={setDeleteModal} />
      )}{" "}
      {preferenceModal && preferences && (
        <EditPreferences
          setData={setData}
          preference={preferences}
          setShowModal={setPreferenceModal}
        />
      )}
      <div className="profile__header-new">
        <Header   handleLogout={handleLogout} />
      </div>
      <div className="profile">
        <div className="profile__header">
          <section
            onClick={() => setHamburger(!hamburger)}
            className={`hamburger ${hamburger ? "active" : ""}`}
          >
            <span></span>
            <span></span>
            <span></span>
          </section>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
            src={InfinityWhiteLogo}
            alt="Infinity logo"
          />

          <ul>
            <li>
              <Link to={"/career-finder"}>Career Finder</Link>
            </li>
            <li>
              <Link to={"/tech-careers"}>Browse Tech Careers</Link>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="https://infinitycareers.notion.site/Team-7c8b89f2ccbc48f5b212528c7b8f6cb3"
                target={"_blank"}
              >
                Meet The Team
              </a>
            </li>
          </ul>

          {data &&
            (!data.avatar || data?.avatar === "image/upload/string" ? (
              <div className="profile__account avatar">
                {data?.first_name ? data?.first_name[0].toUpperCase() : ""}
                {data?.last_name ? data?.last_name[0].toUpperCase() : ""}
              </div>
            ) : (
              <img
                src={getAvatar(data.avatar)}
                alt="avatar"
                className="avatar"
              />
            ))}
          <section className="profile__dropdown">
            <p onClick={handleLogout}>Logout</p>
          </section>
        </div>
        <div className="profile__container">
          <div className={`profile__navbar ${!hamburger && "close"}`}>
            <ul>
              <li onClick={() => handleClickScroll("profile-home")}>
                <Home /> My Profile
              </li>
              <li onClick={() => handleClickScroll("profile-wishlist")}>
                <Books /> Wishlist
              </li>
              <li onClick={() => handleClickScroll("profile-preferences")}>
                <Prefences /> My Preferences
              </li>
            </ul>
          </div>
          {data ? (
            <main className="profile__box">
              <div className="profile__info" id="profile-home">
                <div className="profile__title">My Profile</div>
                <div className="profile__info-container">
                  <div className="profile__info-col-1">
                    {data &&
                      (data.avatar && data?.avatar !== "image/upload/string" ? (
                        <div className="profile__avatar">
                          <img src={getAvatar(data.avatar)} alt="profile pic" />
                          {/* <span>
                          Edit <br /> Avatar
                        </span> */}
                        </div>
                      ) : (
                        <div className="profile__avatar">
                          <div className="profile__avatar-container">
                            {data?.first_name
                              ? data?.first_name[0].toUpperCase()
                              : ""}
                            {data?.last_name
                              ? data?.last_name[0].toUpperCase()
                              : ""}
                          </div>
                          {/* <span>
                          Edit <br /> Avatar
                        </span> */}
                        </div>
                      ))}
                    <div className="profile__info-box">
                      <div className="profile__info-name">
                        {data?.first_name} {data?.last_name}
                      </div>
                      <div className="profile__info-email">{data?.email}</div>
                      <div className="profile__info-button-group">
                        <button onClick={() => navigate("/editProfile")}>
                          Edit Profile
                        </button>
                        <button onClick={() => setShowAvatarModal(true)}>
                          {data.avatar ? "Edit Avatar" : "Add Avatar"}
                        </button>
                      </div>
                      {/* <LinkedInBlue /> */}
                    </div>
                  </div>
                  {data && completionRate !== 100 && (
                    <div className="profile__info-col-2">
                      <div className="profile__info-col-2-title">
                        <div>Profile Completion</div>
                        {/* <span onClick={() => setPreferenceModal(true)}>
                      Set Preferences
                    </span> */}
                      </div>
                      <div className="profile__info-progress-box">
                        <div className="profile__info-progress">
                          <div style={{ width: `${completionRate}%` }}></div>
                        </div>
                        {completionRate}%
                      </div>
                      {!data?.avatar && (
                        <span>✗ Add your personalized avatar</span>
                      )}
                      {(!data?.favorite_brands?.length > 0 ||
                        !data?.skills?.length > 0 ||
                        !data?.favorite_industries?.length) && (
                        <span>✗ Get matched to a career in tech</span>
                      )}
                      {(!data?.favorite_brands?.length > 0 ||
                        !data?.skills?.length > 0 ||
                        !data?.favorite_industries?.length) && (
                        <button onClick={() => navigate("/career-finder")}>
                          Go to Career Finder
                        </button>
                      )}
                    </div>
                  )}
                  {data && completionRate === 100 && (
                    <div className="profile__info-col-3">
                      <div>My Top Skills ⭐️</div>
                      <section>
                        {[
                          ...Array(
                            data.skills.length >= 3 ? 3 : data.skills.length
                          ).keys(),
                        ].map((e) => (
                          <span>{data?.skills[e]?.skill}</span>
                        ))}
                      </section>
                    </div>
                  )}
                </div>
              </div>

              <div className="profile__learnlist">
                <section>
                  <div className="profile__title">
                    Currently Learning 💡
                    <div className="break"></div>
                    <p>
                      Add anything else you are learning here. Literally
                      anything (ie. YouTube videos, e-books, blogs, courses,
                      bootcamps).
                    </p>
                  </div>
                  <main onClick={() => setShowCourseModal(true)}>Add New</main>
                </section>
                <div className="profile__learnlist-container">
                  <div
                    onClick={() => setShowCourseModal(true)}
                    className="profile__learnlist-add"
                  >
                    <span>+</span>
                    Add New
                  </div>
                  <div className="profile__wishlist-carousel">
                    {learnlist?.length > 0 && (
                      <Carousel
                        arrows={false}
                        responsive={wishlistResponsive}
                        renderButtonGroupOutside
                        infinite={true}
                        customButtonGroup={<ButtonGroup />}
                      >
                        {learnlist.map((e) => (
                          <LearnList
                            id={e.id}
                            setDeleteId={e.id}
                            brand={e.brand_title}
                            setShowModal={setDeleteModal}
                            title={e.title}
                            img={e.platform_logo}
                            request={getLearnList}
                            link={e.link}
                          />
                        ))}
                      </Carousel>
                    )}
                  </div>
                </div>
              </div>
              <div className="profile__wishlist" id="profile-wishlist">
                <div className="profile__title">
                  Wishlist <Heart />
                  <div className="break"></div>
                  <p>
                    Browse Tech Careers(link browse tech careers) to add the
                    curriculum + courses of your desired job and training
                    interests. Select ‘Enroll’ to purchase.
                  </p>
                </div>
                <div className="profile__wishlist-button-box">
                  <div className="profile__wishlist-button">
                    <div
                      onClick={() => setWishlist("curriculum")}
                      className={`${wishlist === "curriculum" ? "active" : ""}`}
                    >
                      Curriculum
                    </div>
                    <div
                      onClick={() => setWishlist("courses")}
                      className={`${wishlist === "courses" ? "active" : ""}`}
                    >
                      Courses
                    </div>
                  </div>
                </div>
                <div className="profile__wishlist-carousel">
                  {wishlistData ? (
                    wishlist === "curriculum" ? (
                      <Carousel
                        arrows={false}
                        responsive={wishlistResponsive}
                        renderButtonGroupOutside
                        infinite={true}
                        customButtonGroup={<ButtonGroup />}
                        //   autoPlay={true}
                        //   autoPlaySpeed={2000}
                      >
                        {wishlistData &&
                          wishlistData.curriculums.map((e) => (
                            <TechCareersFilterCard
                              wl={true}
                              title={e.career_title}
                              id={e.id}
                              img={e.curriculum_image}
                            />
                          ))}
                      </Carousel>
                    ) : (
                      <>
                        <Carousel
                          arrows={false}
                          responsive={wishlistResponsive}
                          renderButtonGroupOutside
                          infinite={true}
                          customButtonGroup={<ButtonGroup />}
                          //   autoPlay={true}
                          //   autoPlaySpeed={2000}
                        >
                          {wishlistData.courses.map((e) => (
                            <CourseEnrollCard
                              wl={true}
                              img={e.course_image}
                              id={e.id}
                              title={e.course_title}
                              big={true}
                              profile={data}
                              link={e.affiliate_link}
                            />
                          ))}
                        </Carousel>
                      </>
                    )
                  ) : null}
                </div>
              </div>

              {/* <div className="profile__platforms">
              <div className="profile__title">Link Platforms</div>
              <div className="profile__platforms-container">
                <div>
                  <CourseraPF />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit.suscipit.
                  </p>
                  <button className="connected">Connected</button>
                </div>
                <div>
                  <SkillSharePF />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit.suscipit.
                  </p>
                  <button>Connect</button>
                </div>
                <div>
                  <LinkedInPF />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit.suscipit.
                  </p>
                  <button>Connect</button>
                </div>
              </div>
            </div> */}
              <div className="profile__preferences" id="profile-preferences">
                <div className="profile__title">
                  Preferences
                  <div className="break"></div>
                  <p>
                    Edit your interests to help us build a more personalized
                    experience as you navigate your career.
                  </p>
                </div>
                <div className="profile__preferences-container">
                  <div className="profile__preferences-col-1">
                    <div className="profile__preferences-title">
                      Industries <Industry />{" "}
                      <button onClick={() => setPreferenceModal(true)}>
                        Edit <Pencil />
                      </button>
                    </div>
                    <div className="profile__preferences-industry-tags">
                      {data &&
                        data.favorite_industries.map((e) => (
                          <div>{e.industry_name}</div>
                        ))}
                    </div>
                  </div>
                  <div className="profile__preferences-col-2">
                    <div className="profile__preferences-title">
                      Brands <Brand />{" "}
                      <button onClick={() => setPreferenceModal(true)}>
                        Edit <Pencil />
                      </button>
                    </div>
                    <div className="profile__preferences-brands">
                      {data &&
                        data.favorite_brands.map((e) => (
                          <img src={e?.logo?.slice(13)} alt={e.brand_name} />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile__communities" id="communities">
                <div className="profile__communities-title-box">
                  <div className="profile__title">
                    Communities
                    <div className="break"></div>
                    <p>
                      A strong support system is important along your journey in
                      tech. Check out our partnered Career Communities and add
                      the career communities you have joined.
                    </p>
                  </div>
                  <button onClick={() => setShowCommunityModal(true)}>
                    Add New Community <Sparky />
                  </button>
                </div>
                <div className="profile__wishlist-carousel">
                  {community && (
                    <Carousel
                      arrows={false}
                      responsive={responsive}
                      renderButtonGroupOutside
                      infinite={true}
                      customButtonGroup={<ButtonGroup />}
                      //   autoPlay={true}
                      //   autoPlaySpeed={2000}
                    >
                      {/* {defaultCommunities &&
                      defaultCommunities.map((e) => (
                        <div className="profile__communities-box">
                          <a href={e?.link}>
                            <div
                              className="profile__communities-background"
                              style={{
                                backgroundImage: `url(${
                                  e.logo
                                    ? e?.logo?.slice(13)
                                    : "https://png.pngtree.com/png-vector/20190820/ourlarge/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                                }
                                )`,
                              }}
                            ></div>
                            <div className="profile__communities-name">
                              {e?.name}
                            </div>
                          </a>
                        </div>
                      ))} */}

                      {community &&
                        community.map((e) => (
                          <div className="profile__communities-box">
                            <div
                              className="profile__communities-box-add"
                              onClick={() => {
                                deleteCommunity(e.id);
                              }}
                              style={{
                                color: `${e.community.logo ? "#000" : "#fff"}
                              `,
                              }}
                            >
                              &#x2715;
                            </div>
                            <a
                              target={"_blank"}
                              rel="noreferrer"
                              href={e?.community?.link}
                            >
                              <div
                                className="profile__communities-background"
                                style={{
                                  background: `${
                                    e.community.logo
                                      ? "url(" +
                                        e?.community?.logo?.slice(13) +
                                        ")"
                                      : "#7074e6"
                                  }
                                `,
                                }}
                              >
                                {!e.community.logo && (
                                  <span>{e.community.name}</span>
                                )}
                              </div>
                              <div className="profile__communities-name">
                                {e?.community?.name}
                              </div>
                            </a>
                          </div>
                        ))}
                    </Carousel>
                  )}
                  {/* {community &&
                  community.map((e) => (
                    <div className="profile__communities-box">
                      <a href={e.link}>
                        <img src={getAvatar(e.logo)} alt="" />
                        <div className="profile__communities-name">
                          {e.name}
                        </div>
                      </a>
                    </div>
                  ))} */}
                </div>
              </div>

              {/* <div className="profile__certification">
              <div className="profile__certification-title">
              My Mirco Cetrifications
              </div>
              <p>Coming soon</p>
            </div> */}
              {/* <Footer /> */}
            </main>
          ) : (
            <div
              style={{ marginTop: "10rem" }}
              className="avatarModal__container"
            >
              <div class="lds-ripple">
                <div></div>
                <div></div>
              </div>
              <p>Loading</p>
            </div>
          )}
        </div>
      </div>
      <Footer
        style={{
          marginTop: "0",
        }}
      />
    </>
  );
};

export default Profile;
